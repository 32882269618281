import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { buildQuery } from '../../utils/buildQuery';
import { callApi } from '../../utils/callApi';
import {
  CreateSelection,
  DeleteSelection,
  GetSelections,
  TypeCreateSelectionR,
  TypeDeleteSelectionR,
  TypeGetSelectionsR,
  TypeUpdateSelectionR,
  TypeDeleteSubSelectionR,
  UpdateSelection,
  DeleteSubSelection,
  GetFreeIds,
  TypeGetFreeIdsR,
  SearchFreeIds,
  TypeSearchFreeIdsR,
  TypeResetItemIdR,
  ResetItemId,
  UpdateSelectionPosition,
  TypeUpdatePositionsSelectionR,
} from './actions';
import ActionTypes from './types';
import { ITitleIdsPairs } from '../main/types';
import { IBlock } from '../blocks/types';

function* getSelectionWorker(action: ReturnType<typeof GetSelections.request>): Generator {
  const { id, callBack } = action.payload as TypeGetSelectionsR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/selections/${id}`,
    })) as IBlock[];
    yield put(GetSelections.success(resp));
  } catch (e) {
    success = false;
    yield put(GetSelections.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* getFreeIdsWorker(action: ReturnType<typeof GetFreeIds.request>): Generator {
  const { siteId, callBack } = action.payload as TypeGetFreeIdsR;

  let success = true;
  let resp = null as null | ITitleIdsPairs;
  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: `/selections/ids?siteId=${siteId}`,
    })) as ITitleIdsPairs;
    yield put(GetFreeIds.success(resp));
  } catch (e) {
    success = false;
    yield put(GetFreeIds.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* searchFreeIdsWorker(action: ReturnType<typeof SearchFreeIds.request>): Generator {
  const { data, callBack } = action.payload as TypeSearchFreeIdsR;

  let success = true;
  let resp = null as null | ITitleIdsPairs;
  const query = buildQuery(data);

  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: `/selections/ids?${query}`,
    })) as ITitleIdsPairs;
    yield put(SearchFreeIds.success(resp));
  } catch (e) {
    success = false;
    yield put(SearchFreeIds.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* resetItemIdWorker(action: ReturnType<typeof ResetItemId.request>): Generator {
  const { id, callBack } = action.payload as TypeResetItemIdR;

  let success = true;
  try {
    yield call(callApi, {
      method: 'get',
      path: `/selections/sub-selection/reset/${id}`,
    });
    yield put(ResetItemId.success());
  } catch (e) {
    success = false;
    yield put(ResetItemId.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* createSelectionWorker(action: ReturnType<typeof CreateSelection.request>): Generator {
  const { data, callBack } = action.payload as TypeCreateSelectionR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      data,
      path: '/selections/',
    })) as IBlock;
    yield put(CreateSelection.success(resp));
  } catch (e) {
    success = false;
    yield put(CreateSelection.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* updateSelectionWorker(action: ReturnType<typeof UpdateSelection.request>): Generator {
  const { data, id, callBack } = action.payload as TypeUpdateSelectionR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'put',
      data,
      path: `/selections/${id}`,
    })) as IBlock;
    yield put(UpdateSelection.success(resp));
  } catch (e) {
    success = false;
    yield put(UpdateSelection.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* deleteSubSelectionWorker(action: ReturnType<typeof DeleteSubSelection.request>): Generator {
  const { id, callBack } = action.payload as TypeDeleteSubSelectionR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'delete',
      path: `/selections/sub-selection/${id}`,
    })) as IBlock;
    yield put(DeleteSubSelection.success(resp));
  } catch (e) {
    success = false;
    yield put(DeleteSubSelection.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* deleteSelectionWorker(action: ReturnType<typeof DeleteSelection.request>): Generator {
  const { id, callBack } = action.payload as TypeDeleteSelectionR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'delete',
      path: `/selections/${id}`,
    })) as IBlock;
    yield put(DeleteSelection.success(resp));
  } catch (e) {
    success = false;
    yield put(DeleteSelection.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* updateSelectionPositionsWorker(action: ReturnType<typeof UpdateSelectionPosition.request>): Generator {
  const { data, callBack } = action.payload as TypeUpdatePositionsSelectionR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      data,
      path: `/selections/positions`,
    })) as IBlock[];
    yield put(UpdateSelectionPosition.success(resp));
  } catch (e) {
    success = false;
    yield put(UpdateSelectionPosition.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_SELECTIONS_R, getSelectionWorker);
  yield takeEvery(ActionTypes.GET_FREE_IDS_R, getFreeIdsWorker);
  yield takeEvery(ActionTypes.SEARCH_FREE_IDS_R, searchFreeIdsWorker);
  yield takeEvery(ActionTypes.RESET_ITEM_ID_R, resetItemIdWorker);
  yield takeEvery(ActionTypes.CREATE_SELECTION_R, createSelectionWorker);
  yield takeEvery(ActionTypes.UPDATE_SELECTION_R, updateSelectionWorker);
  yield takeEvery(ActionTypes.DELETE_SUB_SELECTION_R, deleteSubSelectionWorker);
  yield takeEvery(ActionTypes.DELETE_SELECTION_R, deleteSelectionWorker);
  yield takeEvery(ActionTypes.UPDATE_SELECTION_POSITIONS_R, updateSelectionPositionsWorker);
}

export default function* selectionsSaga() {
  yield all([fork(watchFetchRequest)]);
}
