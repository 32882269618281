import { Reducer } from 'redux';
import ActionTypes, { TMainState } from './types';

export const initialState: TMainState = {
  data: null,
  titleIdsPairs: null,
  loading: false,
  errors: undefined,
};

const reducer: Reducer<TMainState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_FREE_IDS_R:
    case ActionTypes.GET_MAIN_BLOCKS_R:
    case ActionTypes.CREATE_MAIN_BLOCK_R:
    case ActionTypes.UPDATE_MAIN_BLOCK_R:
    case ActionTypes.DELETE_MAIN_BLOCK_R:
      return { ...state, loading: true, errors: '' };

    case ActionTypes.GET_MAIN_BLOCKS_S:
      return { ...state, loading: false, data: action.payload };

    case ActionTypes.CREATE_MAIN_BLOCK_S:
      return {
        ...state,
        loading: false,
        data: state.data
          ? {
              blocks: [...state.data.blocks, action.payload].sort((a, b) => new Date(b.setdate).getTime() - new Date(a.setdate).getTime()),
              total: state.data.total + 1,
            }
          : { blocks: [action.payload], total: 1 },
      };

    case ActionTypes.UPDATE_MAIN_BLOCK_S:
      return {
        ...state,
        loading: false,
        data: state.data
          ? {
              blocks: state.data.blocks
                .map((it) => (it.id === action.payload.id ? action.payload : it))
                .sort((a, b) => new Date(b.setdate).getTime() - new Date(a.setdate).getTime()),
            }
          : state.data,
      };

    case ActionTypes.DELETE_SUB_MAIN_BLOCK_S:
      return {
        ...state,
        loading: false,
        data: state.data
          ? {
              blocks: state.data.blocks
                .map((it) =>
                  it.id === action.payload.blockId ? { ...it, subBlocks: it.subBlocks.filter((sub) => sub.id !== action.payload.id) } : it
                )
                .sort((a, b) => new Date(b.setdate).getTime() - new Date(a.setdate).getTime()),
            }
          : state.data,
      };

    case ActionTypes.DELETE_MAIN_BLOCK_S:
      return {
        ...state,
        loading: false,
        data: state.data ? { blocks: state.data.blocks?.filter((it) => it.id !== action.payload.id), total: state.data.total - 1 } : state.data,
      };

    case ActionTypes.GET_FREE_IDS_S:{
      return { ...state, loading: false, errors: undefined, titleIdsPairs: action.payload };}

    case ActionTypes.GET_FREE_IDS_E:
    case ActionTypes.GET_MAIN_BLOCKS_E:
    case ActionTypes.CREATE_MAIN_BLOCK_E:
    case ActionTypes.UPDATE_MAIN_BLOCK_E:
    case ActionTypes.DELETE_SUB_MAIN_BLOCK_E:
    case ActionTypes.DELETE_MAIN_BLOCK_E:
      return { ...state, loading: false, errors: action.payload };

    default:
      return state;
  }
};

export { reducer as MainReducer };
