import { action } from 'typesafe-actions';
import { Actions, TDefRequest } from '../Actions';
import ActionTypes, { IAuthor, TAuthorsState } from './types';

export const setAuthors = (payload: TAuthorsState['data']) => action(ActionTypes.SET_AUTHORS, payload);
export const resetAuthors = () => action(ActionTypes.RESET_AUTHORS, {});

const GetAuthorsAction = new Actions('GET_AUTHORS', ActionTypes);

export interface TypeGetAuthorsR extends TDefRequest {
  data: { id?: number; fio?: string; limit?: number };
}

export const GetAuthors = {
  request: (payload: TypeGetAuthorsR) => GetAuthorsAction.request(payload),
  success: (payload: TAuthorsState['data']) => GetAuthorsAction.success(payload || {}),
  error: (message: string) => GetAuthorsAction.error(message),
};

const GetSearchAuthorsAction = new Actions('GET_SEARCH_AUTHORS', ActionTypes);

export interface TypeGetSearchAuthorsR extends TDefRequest {
  data: { id?: number; fio?: string; limit?: number };
}

export const GetSearchAuthors = {
  request: (payload: TypeGetSearchAuthorsR) => GetSearchAuthorsAction.request(payload),
  success: (payload: IAuthor[]) => GetSearchAuthorsAction.success(payload || {}),
  error: (message: string) => GetSearchAuthorsAction.error(message),
};

const CreateAuthorAction = new Actions('CREATE_AUTHOR', ActionTypes);

export interface TypeCreateAuthorR extends TDefRequest {
  data: FormData;
}

export const CreateAuthor = {
  request: (payload: TypeCreateAuthorR) => CreateAuthorAction.request(payload),
  success: (payload: IAuthor) => CreateAuthorAction.success(payload || {}),
  error: (message: string) => CreateAuthorAction.error(message),
};

const UpdateAuthorAction = new Actions('UPDATE_AUTHOR', ActionTypes);

export interface TypeUpdateAuthorR extends TDefRequest {
  data: FormData;
  id: number;
}

export const UpdateAuthor = {
  request: (payload: TypeUpdateAuthorR) => UpdateAuthorAction.request(payload),
  success: (payload: IAuthor) => UpdateAuthorAction.success(payload || {}),
  error: (message: string) => UpdateAuthorAction.error(message),
};

const DeleteAuthorAction = new Actions('DELETE_AUTHOR', ActionTypes);

export interface TypeDeleteAuthorR extends TDefRequest {
  id: number;
}

export const DeleteAuthor = {
  request: (payload: TypeDeleteAuthorR) => DeleteAuthorAction.request(payload),
  success: (payload: IAuthor) => DeleteAuthorAction.success(payload || {}),
  error: (message: string) => DeleteAuthorAction.error(message),
};
