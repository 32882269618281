import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { callApi } from '../../utils/callApi';
import { GetConfigurator, TypeGetConfiguratorR, TypeUpdateConfiguratorR, UpdateConfigurator } from './actions';
import ActionTypes, { TConfiguratorState } from './types';

function* getConfiguratorWorker(action: ReturnType<typeof GetConfigurator.request>): Generator {
  const { siteId, callBack } = action.payload as TypeGetConfiguratorR;
  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/configurator/${siteId}`,
    })) as TConfiguratorState['data'];
    yield put(GetConfigurator.success(resp));
  } catch (e) {
    success = false;
    yield put(GetConfigurator.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* updateConfiguratorWorker(action: ReturnType<typeof UpdateConfigurator.request>): Generator {
  const { siteId, data, callBack } = action.payload as TypeUpdateConfiguratorR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      data,
      path: `/configurator?siteId=${siteId}`,
    })) as TConfiguratorState['data'];
    yield put(UpdateConfigurator.success(resp));
  } catch (e) {
    success = false;
    yield put(UpdateConfigurator.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_CONFIGURATOR_R, getConfiguratorWorker);
  yield takeEvery(ActionTypes.UPDATE_CONFIGURATOR_R, updateConfiguratorWorker);
}

export default function* configuratorSaga() {
  yield all([fork(watchFetchRequest)]);
}
