import { all, takeEvery, fork, call, put } from 'redux-saga/effects';
import ActionTypes, { TCartState } from './types';
import { SearchCart, GetCart, TypeGetCartR, TypeSearchCartR } from './actions';
import { buildQuery } from '../../utils/buildQuery';
import { callApi } from '../../utils/callApi';

function* getCartWorker(action: ReturnType<typeof GetCart.request>): Generator {
  const { data, callBack } = action.payload as TypeGetCartR;

  let success = true;
  const query = buildQuery(data);
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/cart?${query}`,
    })) as TCartState['data'];
    yield put(GetCart.success(resp));
  } catch (e: any) {
    yield put(GetCart.error(e as string))
    success = false
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* searchCartWorker(action: ReturnType<typeof SearchCart.request>): Generator {
  const { data, callBack } = action.payload as TypeSearchCartR;
  let success = true;
  const query = buildQuery(data);
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/cart?${query}`,
    })) as TCartState['data'];
    yield put(SearchCart.success(resp ? resp.publications : []))
  } catch (e: any) {
    success = false;
    yield put(SearchCart.error(e as string));
  }
  finally{
    if (callBack) yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_CART_R, getCartWorker);
  yield takeEvery(ActionTypes.SEARCH_CART_R, searchCartWorker);
}

export default function* cartSaga() {
  yield all([fork(watchFetchRequest)]);
}
