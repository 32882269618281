import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { callApi } from '../../utils/callApi';
import {
  CreateRubric,
  DeleteRubric,
  GetRubrics,
  TypeCreateRubricR,
  TypeDeleteRubricR,
  TypeGetRubricsR,
  TypeUpdatePositionsR,
  TypeUpdateRubricR,
  UpdatePositions,
  UpdateRubric,
} from './actions';
import ActionTypes, { TRubricsState } from './types';

function* getRubricsWorker(action: ReturnType<typeof GetRubrics.request>): Generator {
  const { id, callBack } = action.payload as TypeGetRubricsR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/rubrics/${id}`,
    })) as TRubricsState['rubrics'];
    yield put(GetRubrics.success(resp));
  } catch (e) {
    success = false;
    yield put(GetRubrics.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* createRubricWorker(action: ReturnType<typeof CreateRubric.request>): Generator {
  const { data, callBack } = action.payload as TypeCreateRubricR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      data,
      path: '/rubrics/',
    })) as TRubricsState['rubrics'];
    yield put(CreateRubric.success(resp));
  } catch (e) {
    success = false;
    yield put(CreateRubric.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* updatePositionsWorker(action: ReturnType<typeof UpdatePositions.request>): Generator {
  const { data, callBack } = action.payload as TypeUpdatePositionsR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      data,
      path: `/rubrics/positions`,
    })) as TRubricsState['rubrics'];
    yield put(UpdatePositions.success(resp));
  } catch (e) {
    success = false;
    yield put(UpdatePositions.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* updateRubricWorker(action: ReturnType<typeof UpdateRubric.request>): Generator {
  const { data, id, callBack } = action.payload as TypeUpdateRubricR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'put',
      data,
      path: `/rubrics/${id}`,
    })) as TRubricsState['rubrics'];
    yield put(UpdateRubric.success(resp));
  } catch (e) {
    success = false;
    yield put(UpdateRubric.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* deleteRubricWorker(action: ReturnType<typeof DeleteRubric.request>): Generator {
  const { id, callBack } = action.payload as TypeDeleteRubricR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'delete',
      path: `/rubrics/${id}`,
    })) as TRubricsState['rubrics'];
    yield put(DeleteRubric.success(resp));
  } catch (e) {
    success = false;
    yield put(DeleteRubric.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_RUBRICS_R, getRubricsWorker);
  yield takeEvery(ActionTypes.CREATE_RUBRIC_R, createRubricWorker);
  yield takeEvery(ActionTypes.UPDATE_POSITIONS_R, updatePositionsWorker);
  yield takeEvery(ActionTypes.UPDATE_RUBRIC_R, updateRubricWorker);
  yield takeEvery(ActionTypes.DELETE_RUBRIC_R, deleteRubricWorker);
}

export default function* rubricsSaga() {
  yield all([fork(watchFetchRequest)]);
}
