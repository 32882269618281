import React from 'react';
import { useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { Rubrics } from '../Rubrics';
import { Box, Button } from '@mui/material';
import { ROUTES } from '../../utils/routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { MENU } from '../../utils/menu';

type PropsType = {
  element: JSX.Element;
};

export const MainWorkspace: React.FC<PropsType> = ({ element }) => {
  const [tab, setTab] = React.useState(1);
  const [name, setName] = React.useState('');

  const { Configurations } = useSelector((store: AppStore) => store);
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (location.pathname.includes('list')) {
      setTab(2);
    } else if (location.pathname.includes('selection')) {
      setTab(3);
    } else {
      setTab(1);
    }
    const obj = MENU(Configurations.siteId).find((item) => item.path !== '/' && location.pathname.includes(item.path));
    if (obj) {
      setName(
        obj.name
          .split(' ')
          .map((name) => checkName(name))
          .join(' ')
      );
    }
  }, [location.pathname, Configurations.siteId]);

  const handleChangeRoute =
    (path = '') =>
    () => {
      navigate(ROUTES.routeChange(path));
    };

  const renderRoutePage = () => {
    if (tab === 1) {
      return <Rubrics />;
    }
    return element;
  };

  const checkName = (name: string) => {
    const index = name.length - 1;
    if (name.substring(index - 1).includes('ми')) {
      return name.replace('и', '');
    }
    let str = name.substring(0, index);
    const endWords = name[index].replace('и', 'ів').replace('ї', 'й');
    return str + endWords;
  };

  return (
    <>
      <Box display={'flex'} mb={2} gap={1}>
        <Button
          variant={tab === 1 ? 'contained' : 'outlined'}
          color={tab === 1 ? 'primary' : 'inherit'}
          onClick={handleChangeRoute()}
        >
          Рубрикатор
        </Button>
        <Button
          variant={tab === 2 ? 'contained' : 'outlined'}
          color={tab === 2 ? 'primary' : 'inherit'}
          onClick={handleChangeRoute('list')}
        >
          Список {name}
        </Button>
        {location.pathname.includes(`catalogues`) ? (
          <Button
            variant={tab === 3 ? 'contained' : 'outlined'}
            color={tab === 3 ? 'primary' : 'inherit'}
            onClick={handleChangeRoute('selection')}
          >
            Добірки
          </Button>
        ) : null}
      </Box>
      {renderRoutePage()}
    </>
  );
};
