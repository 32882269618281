import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { buildQuery } from '../../utils/buildQuery';
import { callApi } from '../../utils/callApi';
import {
  CreateAuthor,
  DeleteAuthor,
  GetAuthors,
  GetSearchAuthors,
  TypeCreateAuthorR,
  TypeDeleteAuthorR,
  TypeGetAuthorsR,
  TypeGetSearchAuthorsR,
  TypeUpdateAuthorR,
  UpdateAuthor,
} from './actions';
import ActionTypes, { IAuthor, TAuthorsState } from './types';

function* getAuthorsWorker(action: ReturnType<typeof GetAuthors.request>): Generator {
  const { data, callBack } = action.payload as TypeGetAuthorsR;

  let success = true;
  const query = buildQuery(data);

  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/authors?${query}`,
    })) as TAuthorsState['data'];
    yield put(GetAuthors.success(resp));
  } catch (e) {
    success = false;
    yield put(GetAuthors.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* getSearchAuthorsWorker(action: ReturnType<typeof GetSearchAuthors.request>): Generator {
  const { data, callBack } = action.payload as TypeGetSearchAuthorsR;

  let success = true;
  let resp = null;
  const query = buildQuery(data);

  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: `/authors?${query}`,
    })) as TAuthorsState['data'];
    yield put(GetSearchAuthors.success(resp?.authors || []));
  } catch (e) {
    success = false;
    yield put(GetSearchAuthors.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp?.authors || []);
  }
}

function* createAuthorWorker(action: ReturnType<typeof CreateAuthor.request>): Generator {
  const { data, callBack } = action.payload as TypeCreateAuthorR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      data,
      path: '/authors/',
    })) as IAuthor;
    yield put(CreateAuthor.success(resp));
  } catch (e) {
    success = false;
    yield put(CreateAuthor.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* updateAuthorWorker(action: ReturnType<typeof UpdateAuthor.request>): Generator {
  const { data, id, callBack } = action.payload as TypeUpdateAuthorR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'put',
      data,
      path: `/authors/${id}`,
    })) as IAuthor;
    yield put(UpdateAuthor.success(resp));
  } catch (e) {
    success = false;
    yield put(UpdateAuthor.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* deleteAuthorWorker(action: ReturnType<typeof DeleteAuthor.request>): Generator {
  const { id, callBack } = action.payload as TypeDeleteAuthorR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'delete',
      path: `/authors/${id}`,
    })) as IAuthor;
    yield put(DeleteAuthor.success(resp));
  } catch (e) {
    success = false;
    yield put(DeleteAuthor.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_AUTHORS_R, getAuthorsWorker);
  yield takeEvery(ActionTypes.GET_SEARCH_AUTHORS_R, getSearchAuthorsWorker);
  yield takeEvery(ActionTypes.CREATE_AUTHOR_R, createAuthorWorker);
  yield takeEvery(ActionTypes.UPDATE_AUTHOR_R, updateAuthorWorker);
  yield takeEvery(ActionTypes.DELETE_AUTHOR_R, deleteAuthorWorker);
}

export default function* authorsSaga() {
  yield all([fork(watchFetchRequest)]);
}
