import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { callApi } from '../../utils/callApi';
import {
  GetBlock,
  TypeGetBlockR,
  TypeUpdateBlockR,
  TypeDeleteSubBlockR,
  UpdateBlock,
  DeleteSubBlock,
  UpdateBlockMonth,
  TypeUpdateBlockMonthR,
  UpdateBlockPublic,
  TypeUpdateBlockPublicR,
  ActualizationBlock,
  TypeActualizationBlockR,
} from './actions';
import ActionTypes, { IBlock } from './types';

function* getBlockWorker(action: ReturnType<typeof GetBlock.request>): Generator {
  const { id, callBack } = action.payload as TypeGetBlockR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/officialdoc-blocks/blocks/${id}`,
    })) as IBlock[];
    yield put(GetBlock.success(resp));
  } catch (e) {
    success = false;
    yield put(GetBlock.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* actualizationBlockWorker(action: ReturnType<typeof ActualizationBlock.request>): Generator {
  const { id, callBack } = action.payload as TypeActualizationBlockR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/officialdoc-blocks/actualization/${id}`,
    })) as IBlock;
    yield put(ActualizationBlock.success(resp));
  } catch (e) {
    success = false;
    yield put(ActualizationBlock.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* updateBlockWorker(action: ReturnType<typeof UpdateBlock.request>): Generator {
  const { id, data, callBack } = action.payload as TypeUpdateBlockR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'put',
      data,
      path: `/officialdoc-blocks/main/${id}`,
    })) as IBlock;
    yield put(UpdateBlock.success(resp));
  } catch (e) {
    success = false;
    yield put(UpdateBlock.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* updateBlockMonthWorker(action: ReturnType<typeof UpdateBlockMonth.request>): Generator {
  const { id, data, callBack } = action.payload as TypeUpdateBlockMonthR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'put',
      data,
      path: `/officialdoc-blocks/month/${id}`,
      isFormData: true,
    })) as IBlock;
    yield put(UpdateBlockMonth.success(resp));
  } catch (e) {
    success = false;
    yield put(UpdateBlockMonth.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* updateBlockPublicWorker(action: ReturnType<typeof UpdateBlockPublic.request>): Generator {
  const { id, data, callBack } = action.payload as TypeUpdateBlockPublicR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'put',
      data,
      path: `/officialdoc-blocks/public/${id}`,
    })) as IBlock;
    yield put(UpdateBlockPublic.success(resp));
  } catch (e) {
    success = false;
    yield put(UpdateBlockPublic.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* deleteSubBlockWorker(action: ReturnType<typeof DeleteSubBlock.request>): Generator {
  const { id, callBack } = action.payload as TypeDeleteSubBlockR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'delete',
      path: `/officialdoc-blocks/sub-block/${id}`,
    })) as IBlock;
    yield put(DeleteSubBlock.success(resp));
  } catch (e) {
    success = false;
    yield put(DeleteSubBlock.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_BLOCK_R, getBlockWorker);
  yield takeEvery(ActionTypes.ACTUALIZATION_BLOCK_R, actualizationBlockWorker);
  yield takeEvery(ActionTypes.UPDATE_BLOCK_R, updateBlockWorker);
  yield takeEvery(ActionTypes.UPDATE_BLOCK_MONTH_R, updateBlockMonthWorker);
  yield takeEvery(ActionTypes.UPDATE_BLOCK_PUBLIC_R, updateBlockPublicWorker);
  yield takeEvery(ActionTypes.DELETE_SUB_BLOCK_R, deleteSubBlockWorker);
}

export default function* officialDocBlocksSaga() {
  yield all([fork(watchFetchRequest)]);
}
