import { Reducer } from 'redux';
import ActionTypes, { TRubricsState } from './types';

const changeRubrics = (rubrics: TRubricsState['rubrics'], newRubrics: TRubricsState['rubrics']) => {
  if (rubrics && newRubrics) {
    const keys = Object.keys(newRubrics);
    const obj = { ...rubrics };
    obj[keys[0] as unknown as number] = newRubrics[keys[0] as unknown as number];
    return obj;
  }
  return newRubrics;
};

export const initialState: TRubricsState = {
  rubrics: null,
  loading: false,
  errors: undefined,
};

const reducer: Reducer<TRubricsState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_RUBRICS_R:
    case ActionTypes.CREATE_RUBRIC_R:
    case ActionTypes.UPDATE_POSITIONS_R:
    case ActionTypes.UPDATE_RUBRIC_R:
    case ActionTypes.DELETE_RUBRIC_R:
      return { ...state, loading: true, errors: '' };

    case ActionTypes.GET_RUBRICS_S:
      return { ...state, loading: false, rubrics: action.payload };

    case ActionTypes.CREATE_RUBRIC_S:
    case ActionTypes.UPDATE_POSITIONS_S:
    case ActionTypes.UPDATE_RUBRIC_S:
    case ActionTypes.DELETE_RUBRIC_S:
      return { ...state, loading: false, rubrics: changeRubrics(state.rubrics, action.payload) };

    case ActionTypes.GET_RUBRICS_E:
    case ActionTypes.CREATE_RUBRIC_E:
    case ActionTypes.UPDATE_POSITIONS_E:
    case ActionTypes.UPDATE_RUBRIC_E:
    case ActionTypes.DELETE_RUBRIC_E:
      return { ...state, loading: false, errors: action.payload };

    default:
      return state;
  }
};

export { reducer as RubricsReducer };
