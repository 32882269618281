import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { callApi } from '../../utils/callApi';
import {
  CreateFormFile,
  TypeCreateFormFileR,
  TypeUpdateFormFileR,
  TypeDeleteFormFileR,
  UpdateFormFile,
  DeleteFormFile,
  UpdateFormFilePosition,
  TypeUpdatePositionsFormFileR,
} from './actions';
import ActionTypes, { IFormFile } from './types';

function* createFormFileWorker(action: ReturnType<typeof CreateFormFile.request>): Generator {
  const { data, url, callBack } = action.payload as TypeCreateFormFileR;

  let success = true;
  let error = null;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      path: url || `/officialdoc-files`,
      data,
      isFormData: true,
    })) as IFormFile;
    yield put(CreateFormFile.success(resp));
  } catch (e) {
    success = false;
    error = e;
    yield put(CreateFormFile.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error);
  }
}

function* updateFormFileWorker(action: ReturnType<typeof UpdateFormFile.request>): Generator {
  const { id, data, url, callBack } = action.payload as TypeUpdateFormFileR;

  let success = true;
  let error = null;
  try {
    const resp = (yield call(callApi, {
      method: 'put',
      data,
      path: url ? `${url}/${id}` : `/officialdoc-files/${id}`,
      isFormData: true,
    })) as IFormFile;
    yield put(UpdateFormFile.success(resp));
  } catch (e) {
    success = false;
    error = e;
    yield put(UpdateFormFile.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error);
  }
}

function* deleteFormFileWorker(action: ReturnType<typeof DeleteFormFile.request>): Generator {
  const { id, url, callBack, deleteAll, form_id, type } = action.payload as TypeDeleteFormFileR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'delete',
      path: url ? `${url}/${id}` : `/officialdoc-files/${id}`,
      data: { deleteAll: Number(deleteAll), form_id, type:type },
    })) as IFormFile[];
    yield put(DeleteFormFile.success(resp));
  } catch (e) {
    success = false;
    yield put(DeleteFormFile.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* updateFormFilePositionsWorker(action: ReturnType<typeof UpdateFormFilePosition.request>): Generator {
  const { data, callBack } = action.payload as TypeUpdatePositionsFormFileR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      data,
      path: `/form-files/positions`,
    })) as IFormFile[];
    yield put(UpdateFormFilePosition.success(resp));
  } catch (e) {
    success = false;
    yield put(UpdateFormFilePosition.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.CREATE_FORM_FILE_R, createFormFileWorker);
  yield takeEvery(ActionTypes.UPDATE_FORM_FILE_R, updateFormFileWorker);
  yield takeEvery(ActionTypes.UPDATE_FORM_FILE_POSITIONS_R, updateFormFilePositionsWorker);
  yield takeEvery(ActionTypes.DELETE_FORM_FILE_R, deleteFormFileWorker);
}

export default function* formFilesSaga() {
  yield all([fork(watchFetchRequest)]);
}
