import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { buildQuery } from '../../utils/buildQuery';
import { callApi } from '../../utils/callApi';
import {
  CreatePublisher,
  DeletePublisher,
  GetPublishers,
  GetOpenPublications,
  TypeCreatePublisherR,
  TypeDeletePublisherR,
  TypeGetPublishersR,
  TypeUpdatePublisherR,
  UpdatePublisher,
  DeleteOpenPublication,
  TypeDeleteOpenPublicationR,
  GetOpenPublicationIdsByCategory,
  TypeOpenPublicationIdsByCategoryR,
  CreateOpenPublication,
  TypeCreateOpenPublicationR,
} from './actions';
import ActionTypes, { TPublishersState, IPublisherDoc, TOpenPublicationsState, IOpenPublication } from './types';

function* getPublishersWorker(action: ReturnType<typeof GetPublishers.request>): Generator {
  const { data, callBack } = action.payload as TypeGetPublishersR;

  let success = true;
  const query = buildQuery(data);
  let resp: any;
  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: `/officialdoc/publishers?${query}`,
    })) as TPublishersState['data'];
    yield put(GetPublishers.success(resp));
  } catch (e) {
    success = false;
    yield put(GetPublishers.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* getOpenPublicationsWorker(action: ReturnType<typeof GetPublishers.request>): Generator {
  const { data, callBack } = action.payload as TypeGetPublishersR;

  let success = true;
  const query = buildQuery(data);
  let resp: any;
  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: `/open-publications?${query}`,
    })) as TOpenPublicationsState['data'];
    yield put(GetOpenPublications.success(resp));
  } catch (e) {
    success = false;
    yield put(GetOpenPublications.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* getOpenPublicationIdsByCategoryWorker(
  action: ReturnType<typeof GetOpenPublicationIdsByCategory.request>
): Generator {
  const { category_id, site_id, id, callBack } = action.payload as TypeOpenPublicationIdsByCategoryR;

  let success = true;
  const query = buildQuery({ category_id, site_id, id });
  let resp: any;
  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: `/open-publications/category-ids?${query}`,
    })) as TPublishersState['data'];
    yield put(GetOpenPublicationIdsByCategory.success(resp));
  } catch (e) {
    success = false;
    yield put(GetOpenPublicationIdsByCategory.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* createPublisherWorker(action: ReturnType<typeof CreatePublisher.request>): Generator {
  const { data, callBack } = action.payload as TypeCreatePublisherR;

  let success = true;
  let error = null;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      data,
      path: '/officialdoc/publisher',
    })) as IPublisherDoc;
    yield put(CreatePublisher.success(resp));
  } catch (e: any) {
    success = false;
    error = e;
    yield put(CreatePublisher.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error);
  }
}

function* updatePublisherWorker(action: ReturnType<typeof UpdatePublisher.request>): Generator {
  const { data, id, callBack } = action.payload as TypeUpdatePublisherR;

  let success = true;
  let error = null;
  try {
    const resp = (yield call(callApi, {
      method: 'put',
      data,
      path: `/officialdoc/publisher/${id}`,
    })) as IPublisherDoc;
    yield put(UpdatePublisher.success(resp));
  } catch (e: any) {
    success = false;
    error = e;
    yield put(UpdatePublisher.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error);
  }
}

function* deletePublisherWorker(action: ReturnType<typeof DeletePublisher.request>): Generator {
  const { id, callBack } = action.payload as TypeDeletePublisherR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'delete',
      path: `/officialdoc/publisher/${id}`,
    })) as IPublisherDoc;
    yield put(DeletePublisher.success(resp));
  } catch (e) {
    success = false;
    yield put(DeletePublisher.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* createOpenPublicationWorker(action: ReturnType<typeof CreateOpenPublication.request>): Generator {
  const { id, category_id, callBack } = action.payload as TypeCreateOpenPublicationR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      path: `/open-publications`,
      data: { id, category_id },
    })) as IOpenPublication;
    yield put(CreateOpenPublication.success(resp));
  } catch (e) {
    success = false;
    yield put(CreateOpenPublication.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* deleteOpenPublicationWorker(action: ReturnType<typeof DeleteOpenPublication.request>): Generator {
  const { id, category_id, callBack } = action.payload as TypeDeleteOpenPublicationR;

  const query = buildQuery({ category_id });
  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'delete',
      path: `/open-publications/${id}?${query}`,
      data: { category_id },
    })) as IOpenPublication;
    yield put(DeleteOpenPublication.success(resp));
  } catch (e) {
    success = false;
    yield put(DeleteOpenPublication.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_PUBLISHERS_R, getPublishersWorker);
  yield takeEvery(ActionTypes.GET_OPEN_PUBLICATION_R, getOpenPublicationsWorker);
  yield takeEvery(ActionTypes.GET_OPEN_PUBLICATION_CATEGORY_IDS_R, getOpenPublicationIdsByCategoryWorker);
  yield takeEvery(ActionTypes.CREATE_PUBLISHER_R, createPublisherWorker);
  yield takeEvery(ActionTypes.CREATE_OPEN_PUBLICATION_R, createOpenPublicationWorker);
  yield takeEvery(ActionTypes.UPDATE_PUBLISHER_R, updatePublisherWorker);
  yield takeEvery(ActionTypes.DELETE_PUBLISHER_R, deletePublisherWorker);
  yield takeEvery(ActionTypes.DELETE_OPEN_PUBLICATION_R, deleteOpenPublicationWorker);
}

export default function* publishersSaga() {
  yield all([fork(watchFetchRequest)]);
}
