export interface TFactoidsState {
  dataBlocks: { blocks: IFactoidBlock[]; total: number } | null;
  data: { factoids: IFactoids[]; total: number } | null;
  blocks: IFactoidBlock[] | null;
  factoids: IFactoids[] | null;
  editData: { type: 'edit' | 'new'; data?: IFactoids } | null;
  editBlockData: { type: 'edit' | 'new'; data?: IFactoidBlock; factoid_id?: number } | null;
  listBlockData: { factoid?: IFactoids } | null;
  readonly loading: boolean;
  readonly errors?: string | undefined;
}

export interface IFactoids {
  id: number;
  siteId: number;
  title: string;
  body?: string;
  tags: string;
  approved: number;
  created_at: Date;
  updated_at: Date;
}

export interface IFactoidBlock {
  id: number;
  factoid_id: number;
  title: string;
  body?: string;
  approved: number;
  position: number;
  created_at: Date;
  updated_at: Date;
}

enum ActionTypes {
  SET_EDIT_FACTOID = '@@factoids/SET_EDIT_FACTOID',
  SET_EDIT_BLOCK_FACTOID = '@@factoids/SET_EDIT_BLOCK_FACTOID',
  SET_FACTOIDS = '@@factoids/SET_FACTOIDS',
  RESET_FACTOIDS = '@@factoids/RESET_FACTOIDS',
  GET_LIST_FACTOID_BLOCKS = '@@factoids/GET_LIST_FACTOID_BLOCKS',

  GET_FACTOID_BLOCKS_R = '@@factoids/GET_LIST_FACTOID_BLOCKS_R',
  GET_FACTOID_BLOCKS_S = '@@factoids/GET_LIST_FACTOID_BLOCKS_S',
  GET_FACTOID_BLOCKS_E = '@@factoids/GET_LIST_FACTOID_BLOCKS_E',

  GET_FACTOIDS_R = '@@factoids/GET_FACTOIDS_R',
  GET_FACTOIDS_S = '@@factoids/GET_FACTOIDS_S',
  GET_FACTOIDS_E = '@@factoids/GET_FACTOIDS_E',

  SEARCH_FACTOIDS_R = '@@factoids/SEARCH_FACTOIDS_R',
  SEARCH_FACTOIDS_S = '@@factoids/SEARCH_FACTOIDS_S',
  SEARCH_FACTOIDS_E = '@@factoids/SEARCH_FACTOIDS_E',

  CREATE_FACTOIDS_R = '@@factoids/CREATE_FACTOIDS_R',
  CREATE_FACTOIDS_S = '@@factoids/CREATE_FACTOIDS_S',
  CREATE_FACTOIDS_E = '@@factoids/CREATE_FACTOIDS_E',

  CREATE_FACTOID_BLOCKS_R = '@@factoids/CREATE_FACTOID_BLOCKS_R',
  CREATE_FACTOID_BLOCKS_S = '@@factoids/CREATE_FACTOID_BLOCKS_S',
  CREATE_FACTOID_BLOCKS_E = '@@factoids/CREATE_FACTOID_BLOCKS_E',

  UPDATE_FACTOIDS_R = '@@factoids/UPDATE_FACTOIDS_R',
  UPDATE_FACTOIDS_S = '@@factoids/UPDATE_FACTOIDS_S',
  UPDATE_FACTOIDS_E = '@@factoids/UPDATE_FACTOIDS_E',

  UPDATE_FACTOID_BLOCKS_R = '@@factoids/UPDATE_FACTOID_BLOCKS_R',
  UPDATE_FACTOID_BLOCKS_S = '@@factoids/UPDATE_FACTOID_BLOCKS_S',
  UPDATE_FACTOID_BLOCKS_E = '@@factoids/UPDATE_FACTOID_BLOCKS_E',

  DELETE_FACTOIDS_R = '@@factoids/DELETE_FACTOIDS_R',
  DELETE_FACTOIDS_S = '@@factoids/DELETE_FACTOIDS_S',
  DELETE_FACTOIDS_E = '@@factoids/DELETE_FACTOIDS_E',

  DELETE_FACTOID_BLOCKS_R = '@@factoids/DELETE_FACTOID_BLOCKS_R',
  DELETE_FACTOID_BLOCKS_S = '@@factoids/DELETE_FACTOID_BLOCKS_S',
  DELETE_FACTOID_BLOCKS_E = '@@factoids/DELETE_FACTOID_BLOCKS_E',
}

export default ActionTypes;
