
export interface TCartState {
    data: { publications: any[]; total: number } | null;
    publications: any[] | null;
    readonly loading: boolean;
    readonly errors?: string | undefined;
}




enum ActionTypes {
    SET_CART = '@@CART/SET_CART',
    RESET_CART = '@@CART/RESET_CART',
  
    GET_CART_R = '@@CART/GET_CART_R',
    GET_CART_S = '@@CART/GET_CART_S',
    GET_CART_E = '@@CART/GET_CART_E',
  
    SEARCH_CART_R = '@@CART/SEARCH_CART_R',
    SEARCH_CART_S = '@@CART/SEARCH_CART_S',
    SEARCH_CART_E = '@@CART/SEARCH_CART_E',
  
  }
  
  export default ActionTypes;