import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { buildQuery } from '../../utils/buildQuery';
import { callApi } from '../../utils/callApi';
import {
  CreateService,
  DeleteBlock,
  DeleteService,
  GetServiceBlocks,
  GetService,
  GetServices,
  TypeCreateServiceR,
  TypeDeleteBlockR,
  TypeDeleteServiceR,
  TypeGetBlocksR,
  TypeGetServiceR,
  TypeGetServicesR,
  TypeUpdateBlocksR,
  TypeUpdateServiceR,
  UpdateBlocks,
  UpdateService,
  UpdatePosition,
  TypeUpdatePositionR,
} from './actions';
import ActionTypes, { TServicesState } from './types';

function* getServicesWorker(action: ReturnType<typeof GetServices.request>): Generator {
  const { data, callBack } = action.payload as TypeGetServicesR;

  let success = true;
  const query = buildQuery(data);

  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/services?${query}`,
    })) as TServicesState['services'];
    yield put(GetServices.success(resp));
  } catch (e) {
    success = false;
    yield put(GetServices.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* getServiceWorker(action: ReturnType<typeof GetService.request>): Generator {
  const { id, callBack } = action.payload as TypeGetServiceR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/services/service/${id}`,
    })) as TServicesState['current'];
    yield put(GetService.success(resp));
  } catch (e) {
    success = false;
    yield put(GetService.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* createServiceWorker(action: ReturnType<typeof CreateService.request>): Generator {
  const { data, callBack } = action.payload as TypeCreateServiceR;

  let success = true;
  let resp = null;

  try {
    resp = (yield call(callApi, {
      method: 'post',
      data,
      path: `/services/service`,
    })) as TServicesState['current'];
    yield put(CreateService.success(resp));
  } catch (e) {
    success = false;
    yield put(CreateService.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* updateServiceWorker(action: ReturnType<typeof UpdateService.request>): Generator {
  const { id, data, callBack } = action.payload as TypeUpdateServiceR;

  let success = true;
  let resp = null;

  try {
    resp = (yield call(callApi, {
      method: 'put',
      data,
      path: `/services/service/${id}`,
    })) as TServicesState['current'];
    yield put(UpdateService.success(resp));
  } catch (e) {
    success = false;
    yield put(UpdateService.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* updatePositionWorker(action: ReturnType<typeof UpdatePosition.request>): Generator {
  const { data, callBack } = action.payload as TypeUpdatePositionR;

  let success = true;
  let resp = null;

  try {
    resp = (yield call(callApi, {
      method: 'patch',
      data,
      path: `/services/position`,
    })) as TServicesState['services'];
    yield put(UpdatePosition.success(resp));
  } catch (e) {
    success = false;
    yield put(UpdatePosition.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* deleteServiceWorker(action: ReturnType<typeof DeleteService.request>): Generator {
  const { id, callBack } = action.payload as TypeDeleteServiceR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'delete',
      path: `/services/service/${id}`,
    })) as TServicesState['current'];
    yield put(DeleteService.success(resp));
  } catch (e) {
    success = false;
    yield put(DeleteService.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* getBlocksWorker(action: ReturnType<typeof GetServiceBlocks.request>): Generator {
  const { siteId, callBack } = action.payload as TypeGetBlocksR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/services/blocks/${siteId}`,
    })) as TServicesState['blocks'];
    yield put(GetServiceBlocks.success(resp));
  } catch (e) {
    success = false;
    yield put(GetServiceBlocks.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* updateBlocksWorker(action: ReturnType<typeof UpdateBlocks.request>): Generator {
  const { data, callBack } = action.payload as TypeUpdateBlocksR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'put',
      data,
      path: `/services/blocks`,
    })) as TServicesState['blocks'];
    yield put(UpdateBlocks.success(resp));
  } catch (e) {
    success = false;
    yield put(UpdateBlocks.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* deleteBlockWorker(action: ReturnType<typeof DeleteBlock.request>): Generator {
  const { serviceId, typeId, callBack } = action.payload as TypeDeleteBlockR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'delete',
      path: `/services/blocks/${serviceId}-${typeId}`,
    })) as TServicesState['blocks'];
    yield put(DeleteBlock.success(resp));
  } catch (e) {
    success = false;
    yield put(DeleteBlock.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_SERVICES_R, getServicesWorker);
  yield takeEvery(ActionTypes.GET_SERVICE_R, getServiceWorker);
  yield takeEvery(ActionTypes.CREATE_SERVICE_R, createServiceWorker);
  yield takeEvery(ActionTypes.UPDATE_SERVICE_R, updateServiceWorker);
  yield takeEvery(ActionTypes.UPDATE_POSITION_R, updatePositionWorker);
  yield takeEvery(ActionTypes.DELETE_SERVICE_R, deleteServiceWorker);
  yield takeEvery(ActionTypes.GET_BLOCKS_R, getBlocksWorker);
  yield takeEvery(ActionTypes.UPDATE_BLOCKS_R, updateBlocksWorker);
  yield takeEvery(ActionTypes.DELETE_BLOCK_R, deleteBlockWorker);
}

export default function* servicesSaga() {
  yield all([fork(watchFetchRequest)]);
}
