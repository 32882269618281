import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../utils/routes';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { ConfigurationsActions } from '../../store/configurations/actions';
import { CalendarForm } from '../../components/Calendar/CalendarForm';
import { GetEventById } from '../../store/calendar/actions';

export const CoppyEvent: React.FC = () => {
  const { Calendar } = useSelector((store: AppStore) => store);
  const { search } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    const query = new URLSearchParams(search);
    const siteId = query.get('site_id');
    if (siteId) {
      dispatch(ConfigurationsActions.setSiteId(Number(siteId)));
    }
  }, [dispatch, search]);
  
  React.useEffect(() => {
    if (id) {
      dispatch(GetEventById.request({ id: Number(id) }));
    }
  }, [dispatch, id]);
  
  if (!Calendar.current)
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100vh'}>
        <CircularProgress color='secondary' />
      </Box>
    );

  return <CalendarForm type='coppy' data={Calendar.current} handleCancel={() => navigate(ROUTES.routeChange(''))} />;
};
