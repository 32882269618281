import { action } from 'typesafe-actions';
import { Actions, TDefRequest } from '../Actions';
import ActionTypes, { IOfficialDocFile, TOfficialDocFilesState } from './types';

export const setData = (payload: TOfficialDocFilesState['data']) => action(ActionTypes.SET_DATA, payload);

const CreateFileAction = new Actions('CREATE_FILE', ActionTypes);
export interface TypeCreateFileR extends TDefRequest {
  data: FormData;
  url?: string;
}
export const CreateFile = {
  request: (payload: TypeCreateFileR) => CreateFileAction.request(payload),
  success: (payload: IOfficialDocFile) => CreateFileAction.success(payload || {}),
  error: (message: string) => CreateFileAction.error(message),
};

const UpdateFileAction = new Actions('UPDATE_FILE', ActionTypes);
export interface TypeUpdateFileR extends TDefRequest {
  data: FormData;
  id: number;
  url?: string;
}
export const UpdateFile = {
  request: (payload: TypeUpdateFileR) => UpdateFileAction.request(payload),
  success: (payload: IOfficialDocFile) => UpdateFileAction.success(payload || {}),
  error: (message: string) => UpdateFileAction.error(message),
};

const DeleteFileAction = new Actions('DELETE_FILE', ActionTypes);
export interface TypeDeleteFileR extends TDefRequest {
  id: number;
  url?: string;
}
export const DeleteFile = {
  request: (payload: TypeDeleteFileR) => DeleteFileAction.request(payload),
  success: (payload: IOfficialDocFile) => DeleteFileAction.success(payload || {}),
  error: (message: string) => DeleteFileAction.error(message),
};
