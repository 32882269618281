import { Actions, TDefRequest } from '../Actions';
import ActionTypes, { TRubricsState } from './types';

const GetRubricsAction = new Actions('GET_RUBRICS', ActionTypes);

export interface TypeGetRubricsR extends TDefRequest {
  id: number;
}

export const GetRubrics = {
  request: (payload: TypeGetRubricsR) => GetRubricsAction.request(payload),
  success: (payload: TRubricsState['rubrics']) => GetRubricsAction.success(payload || {}),
  error: (message: string) => GetRubricsAction.error(message),
};

const CreateRubricAction = new Actions('CREATE_RUBRIC', ActionTypes);

export interface TypeCreateRubricR extends TDefRequest {
  data: {
    mainId: number | null;
    categoryId: number;
    siteId: number;
    name: string;
    docId?: number;
  };
}

export const CreateRubric = {
  request: (payload: TypeCreateRubricR) => CreateRubricAction.request(payload),
  success: (payload: TRubricsState['rubrics']) => CreateRubricAction.success(payload || {}),
  error: (message: string) => CreateRubricAction.error(message),
};

const UpdatePositionsAction = new Actions('UPDATE_POSITIONS', ActionTypes);

export interface TypeUpdatePositionsR extends TDefRequest {
  data: {
    id: number;
    position: number;
  }[];
}

export const UpdatePositions = {
  request: (payload: TypeUpdatePositionsR) => UpdatePositionsAction.request(payload),
  success: (payload: TRubricsState['rubrics']) => UpdatePositionsAction.success(payload || {}),
  error: (message: string) => UpdatePositionsAction.error(message),
};

const UpdateRubricAction = new Actions('UPDATE_RUBRIC', ActionTypes);

export interface TypeUpdateRubricR extends TDefRequest {
  data: {
    mainId: number | null;
    categoryId: number;
    siteId: number;
    name: string;
    docId?: number;
  };
  id: number;
}

export const UpdateRubric = {
  request: (payload: TypeUpdateRubricR) => UpdateRubricAction.request(payload),
  success: (payload: TRubricsState['rubrics']) => UpdateRubricAction.success(payload || {}),
  error: (message: string) => UpdateRubricAction.error(message),
};

const DeleteRubricAction = new Actions('DELETE_RUBRIC', ActionTypes);

export interface TypeDeleteRubricR extends TDefRequest {
  id: number;
}

export const DeleteRubric = {
  request: (payload: TypeDeleteRubricR) => DeleteRubricAction.request(payload),
  success: (payload: TRubricsState['rubrics']) => DeleteRubricAction.success(payload || {}),
  error: (message: string) => DeleteRubricAction.error(message),
};
