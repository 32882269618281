import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { AppStore } from '../store/applicationState';
import { useEffect } from 'react';
import { configSites } from '../config';

export const RedirectLaw: React.FC = () => {
  const location = useLocation();
  const path = location.pathname;
  const hash = location.hash;
  const { Configurations } = useSelector((store: AppStore) => store);

  useEffect(() => {
    if (configSites[Configurations.siteId]?.url) {
      window.location.href = `${configSites[Configurations.siteId]?.url}${path}${hash}`;
    }
  }, [Configurations, path, hash]);
  return <></>;
};
