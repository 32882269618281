export interface TOfficialDocBlocksState {
  data: IBlock[] | null;
  editData: IBlock | null;
  readonly loading: boolean;
  readonly errors?: string | undefined;
}

export interface IBlock {
  id: number;
  siteId: number;
  name: string;
  title: string;
  description: string;
  image: string;
  isMonth: number;
  subBlocks: ISubBlock[];
  adate: Date;
  update: Date;
  approved: number;
  docId: number;
  docName: string;
}

export interface ISubBlock {
  id: number;
  blockId: number;
  siteId: number;
  position: number;
  title: string;
  label: string;
  url: string;
  viewBlockId: number;
  isChange?: boolean;
}

enum ActionTypes {
  SET_EDIT_DATA = '@@officialDocBlocks/SET_EDIT_DATA',

  GET_BLOCK_R = '@@officialDocBlocks/GET_BLOCK_R',
  GET_BLOCK_S = '@@officialDocBlocks/GET_BLOCK_S',
  GET_BLOCK_E = '@@officialDocBlocks/GET_BLOCK_E',

  ACTUALIZATION_BLOCK_R = '@@officialDocBlocks/ACTUALIZATION_BLOCK_R',
  ACTUALIZATION_BLOCK_S = '@@officialDocBlocks/ACTUALIZATION_BLOCK_S',
  ACTUALIZATION_BLOCK_E = '@@officialDocBlocks/ACTUALIZATION_BLOCK_E',

  UPDATE_BLOCK_R = '@@officialDocBlocks/UPDATE_BLOCK_R',
  UPDATE_BLOCK_S = '@@officialDocBlocks/UPDATE_BLOCK_S',
  UPDATE_BLOCK_E = '@@officialDocBlocks/UPDATE_BLOCK_E',

  UPDATE_BLOCK_MONTH_R = '@@officialDocBlocks/UPDATE_BLOCK_MONTH_R',
  UPDATE_BLOCK_MONTH_S = '@@officialDocBlocks/UPDATE_BLOCK_MONTH_S',
  UPDATE_BLOCK_MONTH_E = '@@officialDocBlocks/UPDATE_BLOCK_MONTH_E',

  UPDATE_BLOCK_PUBLIC_R = '@@officialDocBlocks/UPDATE_BLOCK_PUBLIC_R',
  UPDATE_BLOCK_PUBLIC_S = '@@officialDocBlocks/UPDATE_BLOCK_PUBLIC_S',
  UPDATE_BLOCK_PUBLIC_E = '@@officialDocBlocks/UPDATE_BLOCK_PUBLIC_E',

  DELETE_SUB_BLOCK_R = '@@officialDocBlocks/DELETE_SUB_BLOCK_R',
  DELETE_SUB_BLOCK_S = '@@officialDocBlocks/DELETE_SUB_BLOCK_S',
  DELETE_SUB_BLOCK_E = '@@officialDocBlocks/DELETE_SUB_BLOCK_E',
}

export default ActionTypes;
