import {Actions, TDefRequest} from "../Actions";
import ActionTypes, { TEditorsState } from "./types";

export interface TypeGetEditorsR extends TDefRequest {
    data: {
        page?: number;
        id?: string;
        name?: string;
    };
}

const GetEditorsSelectAction = new Actions('GET_EDITORS_SELECT', ActionTypes);
export const GetEditorsSelect = {
    request: (payload: TypeGetEditorsR) => GetEditorsSelectAction.request(payload),
    success: (payload: TEditorsState['selectList']) => GetEditorsSelectAction.success(payload || {}),
    error: (message: string) => GetEditorsSelectAction.error(message),
};