export interface TEditorsState {
    selectList: { id: number, fio: string }[] | [];
    readonly loading: boolean;
    readonly errors?: string | undefined;
}

enum ActionTypes {
    GET_EDITORS_SELECT_S = '@@editors/GET_EDITORS_SELECT_S',
    GET_EDITORS_SELECT_R = '@@editors/GET_EDITORS_SELECT_R',
    GET_EDITORS_SELECT_E = '@@editors/GET_EDITORS_SELECT_E',
}

export default ActionTypes;