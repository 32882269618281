import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IDataOpt } from '../components/ui/SelectSearchReq';
import { AppStore } from '../store/applicationState';
import { GetSearchCommentators } from '../store/ratings/actions';

export const useSearchCommentators = () => {
  const [options, setOptions] = React.useState<IDataOpt[] | undefined>();

  const { Ratings } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (Ratings.data && Ratings.data.commentators) {
      const opt = Ratings.data.commentators.map((it) => ({ id: it.id, label: it.fio }));
      setOptions(opt);
    }
  }, [Ratings.data]);

  const handleSearchCommentators = (value: string | IDataOpt, callBack?: (resData: IDataOpt[]) => void) => {
    if (value) {
      dispatch(
        GetSearchCommentators.request({
          data: { fio: (value as unknown as IDataOpt).title || String(value) },
          callBack: (succ, data) => {
            if (succ && Array.isArray(data) && callBack) {
              const opt = data.map((it) => ({ id: it.id, label: it.fio  }));
              callBack(opt);
            } else {
              callBack && callBack(options || []);
            }
          },
        })
      );
    } else {
      callBack && callBack(options || []);
    }
  };

  return {
    options,
    handleSearchCommentators,
  };
};
