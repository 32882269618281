import { action } from 'typesafe-actions';
import { Actions, TDefRequest } from '../Actions';
import ActionTypes, { IBlock, ISubBlock, TOfficialDocBlocksState } from './types';

export const setEditData = (payload: TOfficialDocBlocksState['editData']) => action(ActionTypes.SET_EDIT_DATA, payload);

const GetBlockAction = new Actions('GET_BLOCK', ActionTypes);
export interface TypeGetBlockR extends TDefRequest {
  id: number;
}
export const GetBlock = {
  request: (payload: TypeGetBlockR) => GetBlockAction.request(payload),
  success: (payload: IBlock[]) => GetBlockAction.success(payload || {}),
  error: (message: string) => GetBlockAction.error(message),
};

const ActualizationBlockAction = new Actions('ACTUALIZATION_BLOCK', ActionTypes);
export interface TypeActualizationBlockR extends TDefRequest {
  id: number;
}
export const ActualizationBlock = {
  request: (payload: TypeActualizationBlockR) => ActualizationBlockAction.request(payload),
  success: (payload: IBlock) => ActualizationBlockAction.success(payload || {}),
  error: (message: string) => ActualizationBlockAction.error(message),
};

const UpdateBlockAction = new Actions('UPDATE_BLOCK', ActionTypes);
export interface TypeUpdateBlockR extends TDefRequest {
  data: {
    name: string;
    subBlocks?: ISubBlock[];
  };
  id: number;
}
export const UpdateBlock = {
  request: (payload: TypeUpdateBlockR) => UpdateBlockAction.request(payload),
  success: (payload: IBlock) => UpdateBlockAction.success(payload || {}),
  error: (message: string) => UpdateBlockAction.error(message),
};

const UpdateBlockMonthAction = new Actions('UPDATE_BLOCK_MONTH', ActionTypes);
export interface TypeUpdateBlockMonthR extends TDefRequest {
  data: FormData;
  id: number;
}
export const UpdateBlockMonth = {
  request: (payload: TypeUpdateBlockMonthR) => UpdateBlockMonthAction.request(payload),
  success: (payload: IBlock) => UpdateBlockMonthAction.success(payload || {}),
  error: (message: string) => UpdateBlockMonthAction.error(message),
};

const UpdateBlockPublicAction = new Actions('UPDATE_BLOCK_PUBLIC', ActionTypes);
export interface TypeUpdateBlockPublicR extends TDefRequest {
  data: {
    approved: number;
  };
  id: number;
}
export const UpdateBlockPublic = {
  request: (payload: TypeUpdateBlockPublicR) => UpdateBlockPublicAction.request(payload),
  success: (payload: IBlock) => UpdateBlockPublicAction.success(payload || {}),
  error: (message: string) => UpdateBlockPublicAction.error(message),
};

const DeleteSubBlockAction = new Actions('DELETE_SUB_BLOCK', ActionTypes);
export interface TypeDeleteSubBlockR extends TDefRequest {
  id: number;
}
export const DeleteSubBlock = {
  request: (payload: TypeDeleteSubBlockR) => DeleteSubBlockAction.request(payload),
  success: (payload: IBlock) => DeleteSubBlockAction.success(payload || {}),
  error: (message: string) => DeleteSubBlockAction.error(message),
};
