import { Reducer } from 'redux';
import ActionTypes, { TOfficialDocFilesState } from './types';

export const initialState: TOfficialDocFilesState = {
  data: null,
  loading: false,
  errors: undefined,
};

const reducer: Reducer<TOfficialDocFilesState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CREATE_FILE_R:
    case ActionTypes.UPDATE_FILE_R:
    case ActionTypes.DELETE_FILE_R:
      return { ...state, loading: true, errors: '' };

    case ActionTypes.CREATE_FILE_E:
    case ActionTypes.UPDATE_FILE_E:
    case ActionTypes.DELETE_FILE_E:
      return { ...state, loading: false, errors: action.payload };

    case ActionTypes.SET_DATA:
      return { ...state, data: action.payload };

    case ActionTypes.CREATE_FILE_S:
      return { ...state, loading: false, data: state.data ? [...state.data, action.payload] : [action.payload] };

    case ActionTypes.UPDATE_FILE_S:
      return {
        ...state,
        loading: false,
        data: state.data ? state.data.map((it) => (it.id === action.payload.id ? action.payload : it)) : state.data,
      };

    case ActionTypes.DELETE_FILE_S:
      return { ...state, loading: false, data: state.data ? state.data.filter((it) => it.id !== action.payload.id) : state.data };

    default:
      return state;
  }
};

export { reducer as OfficialDocFilesReducer };
