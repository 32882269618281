import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { callApi } from '../../utils/callApi';
import { CreateFile, TypeCreateFileR, TypeUpdateFileR, TypeDeleteFileR, UpdateFile, DeleteFile } from './actions';
import ActionTypes, { IOfficialDocFile } from './types';

function* createFileWorker(action: ReturnType<typeof CreateFile.request>): Generator {
  const { data, url, callBack } = action.payload as TypeCreateFileR;

  let success = true;
  let error = null;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      path: url || `/officialdoc-files`,
      data,
      isFormData: true,
    })) as IOfficialDocFile;
    yield put(CreateFile.success(resp));
  } catch (e) {
    success = false;
    error = e;
    yield put(CreateFile.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error);
  }
}

function* updateFileWorker(action: ReturnType<typeof UpdateFile.request>): Generator {
  const { id, data, url, callBack } = action.payload as TypeUpdateFileR;

  let success = true;
  let error = null;
  try {
    const resp = (yield call(callApi, {
      method: 'put',
      data,
      path: url ? `${url}/${id}` : `/officialdoc-files/${id}`,
      isFormData: true,
    })) as IOfficialDocFile;
    yield put(UpdateFile.success(resp));
  } catch (e) {
    success = false;
    error = e;
    yield put(UpdateFile.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error);
  }
}

function* deleteFileWorker(action: ReturnType<typeof DeleteFile.request>): Generator {
  const { id, url, callBack } = action.payload as TypeDeleteFileR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'delete',
      path: url ? `${url}/${id}` : `/officialdoc-files/${id}`,
    })) as IOfficialDocFile;
    yield put(DeleteFile.success(resp));
  } catch (e) {
    success = false;
    yield put(DeleteFile.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.CREATE_FILE_R, createFileWorker);
  yield takeEvery(ActionTypes.UPDATE_FILE_R, updateFileWorker);
  yield takeEvery(ActionTypes.DELETE_FILE_R, deleteFileWorker);
}

export default function* officialDocFilesSaga() {
  yield all([fork(watchFetchRequest)]);
}
