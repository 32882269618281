import { ISubBlock, ITitleIdsPairs } from '../main/types';

export interface TBlocksState {
  blocks: IBlock[] | null;
  titleIdsPairs: ITitleIdsPairs | null;
  readonly loading: boolean;
  readonly errors?: string | undefined;
}

export interface IBlock {
  id: number;
  siteId: number;
  name: string;
  subBlocks: ISubBlock[];
  isPublic: number;
  adate: Date;
  position: number;
  anchor: string;
}

export interface IIds {
  [x: string]: number[];
}

enum ActionTypes {
  GET_BLOCKS_R = '@@blocks/GET_BLOCKS_R',
  GET_BLOCKS_S = '@@blocks/GET_BLOCKS_S',
  GET_BLOCKS_E = '@@blocks/GET_BLOCKS_E',

  GET_FREE_IDS_R = '@@blocks/GET_FREE_IDS_R',
  GET_FREE_IDS_S = '@@blocks/GET_FREE_IDS_S',
  GET_FREE_IDS_E = '@@blocks/GET_FREE_IDS_E',

  SEARCH_FREE_IDS_R = '@@blocks/SEARCH_FREE_IDS_R',
  SEARCH_FREE_IDS_S = '@@blocks/SEARCH_FREE_IDS_S',
  SEARCH_FREE_IDS_E = '@@blocks/SEARCH_FREE_IDS_E',

  RESET_ITEM_ID_R = '@@blocks/RESET_ITEM_ID_R',
  RESET_ITEM_ID_S = '@@blocks/RESET_ITEM_ID_S',
  RESET_ITEM_ID_E = '@@blocks/RESET_ITEM_ID_E',

  CREATE_BLOCK_R = '@@blocks/CREATE_BLOCK_R',
  CREATE_BLOCK_S = '@@blocks/CREATE_BLOCK_S',
  CREATE_BLOCK_E = '@@blocks/CREATE_BLOCK_E',

  UPDATE_BLOCK_R = '@@blocks/UPDATE_BLOCK_R',
  UPDATE_BLOCK_S = '@@blocks/UPDATE_BLOCK_S',
  UPDATE_BLOCK_E = '@@blocks/UPDATE_BLOCK_E',

  DELETE_SUB_BLOCK_R = '@@blocks/DELETE_SUB_BLOCK_R',
  DELETE_SUB_BLOCK_S = '@@blocks/DELETE_SUB_BLOCK_S',
  DELETE_SUB_BLOCK_E = '@@blocks/DELETE_SUB_BLOCK_E',

  DELETE_BLOCK_R = '@@blocks/DELETE_BLOCK_R',
  DELETE_BLOCK_S = '@@blocks/DELETE_BLOCK_S',
  DELETE_BLOCK_E = '@@blocks/DELETE_BLOCK_E',

  UPDATE_BLOCK_POSITIONS_R = '@@blocks/UPDATE_BLOCK_POSITIONS_R',
  UPDATE_BLOCK_POSITIONS_S = '@@blocks/UPDATE_BLOCK_POSITIONS_S',
  UPDATE_BLOCK_POSITIONS_E = '@@blocks/UPDATE_BLOCK_POSITIONS_E',
}

export default ActionTypes;
