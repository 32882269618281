export interface TConfiguratorState {
  data: IConfigurator | null;
  readonly loading: boolean;
  readonly errors?: string | undefined;
}

export interface IConfigurator {
  id: number;
  pb_name: string;
  pb_url: string;
  all_updates_name: string; 
  all_updates_text: string;
}

enum ActionTypes {
  GET_CONFIGURATOR_R = '@@cofigurator/GET_CONFIGURATOR_R',
  GET_CONFIGURATOR_S = '@@cofigurator/GET_CONFIGURATOR_S',
  GET_CONFIGURATOR_E = '@@cofigurator/GET_CONFIGURATOR_E',

  UPDATE_CONFIGURATOR_R = '@@cofigurator/UPDATE_CONFIGURATOR_R',
  UPDATE_CONFIGURATOR_S = '@@cofigurator/UPDATE_CONFIGURATOR_S',
  UPDATE_CONFIGURATOR_E = '@@cofigurator/UPDATE_CONFIGURATOR_E',
}

export default ActionTypes;
