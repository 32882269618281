import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../utils/routes';
import { useDispatch, useSelector } from 'react-redux';
import { GetBlocks } from '../../store/blocks/actions';
import { GetMainBlocks } from '../../store/main/actions';
import { AppStore } from '../../store/applicationState';
import { GetService, UpdateService, TypeUpdateServiceR } from '../../store/services/actions';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { ConfigurationsActions } from '../../store/configurations/actions';
import { ServiceForm } from '../../components/Services';

export const EditService: React.FC = () => {
  const { Services } = useSelector((store: AppStore) => store);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (id) {
      dispatch(GetService.request({ id: Number(id) }));
    }
  }, [dispatch, id]);

  React.useEffect(() => {
    if (Services.current) {
      dispatch(ConfigurationsActions.setSiteId(Services.current.site_id));
    }
  }, [dispatch, Services]);

  const handleUpdate = (data: TypeUpdateServiceR['data'], callBack: (err?: string) => void) => {
    if (!Services.current) return null;

    dispatch(
      UpdateService.request({
        data,
        id: Services.current.id,
        callBack: (success, dta) => {
          if (!success) {
            if (dta?.status === 409) callBack('Такий заголовок вже використовується');
            else callBack('Сталася помилка');
          } else {
            callBack();
          }
          if (success && Services.current?.is_public === 1 && data.is_public === 0) {
            dispatch(GetBlocks.request({ id: Services.current.site_id }));
            dispatch(GetMainBlocks.request({ id: Services.current.site_id }));
          }
        },
      })
    );
  };

  if (!Services.current)
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100vh'}>
        <CircularProgress color='secondary' />
      </Box>
    );

  return (
    <ServiceForm
      type='edit'
      data={Services.current}
      handleSubmit={handleUpdate}
      handleCancel={() => navigate(ROUTES.routeChange('list'))}
    />
  );
};
