import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { buildQuery } from '../../utils/buildQuery';
import {
  GetRatings,
  GetSearchCommentators,
  SearchRatings,
  TypeGetRatingsR,
  TypeGetSearchCommentatorsR,
  TypeSearchRatingsR,
  TypeSearchRatingsS,
} from './actions';
import ActionTypes, { TRatingsState } from './types';
import { callApi } from '../../utils/callApi';

function* getRatingsWorker(action: ReturnType<typeof GetRatings.request>): Generator {
  const { data, callBack } = action.payload as TypeGetRatingsR;

  let success = true;
  const query = buildQuery(data);
  let resp: TRatingsState['data'] | null = null;

  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: `/ratings?${query}`,
    })) as TRatingsState['data'];
    yield put(GetRatings.success(resp));
  } catch (e) {
    success = false;
    yield put(GetRatings.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* searchRatingsWorker(action: ReturnType<typeof SearchRatings.request>): Generator {
  const { data, callBack } = action.payload as TypeSearchRatingsR;

  let success = true;
  const query = buildQuery(data);
  let resp: TypeSearchRatingsS | null = null;
  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: `/ratings?${query}`,
    })) as TypeSearchRatingsS;
    yield put(SearchRatings.success(resp ? resp : null));
  } catch (e) {
    success = false;
    yield put(SearchRatings.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* getSearchCommentatorsWorker(action: ReturnType<typeof GetSearchCommentators.request>): Generator {
  const { data, callBack } = action.payload as TypeGetSearchCommentatorsR;

  let success = true;
  let resp:
    | {
        id: number;
        fio: string;
      }[]
    | null = null;
  const query = buildQuery(data);

  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: `/ratings/commentators?${query}`,
    })) as {
      id: number;
      fio: string;
    }[] as {
      id: number;
      fio: string;
    }[];
    yield put(GetSearchCommentators.success(resp || []));
  } catch (e) {
    success = false;
    yield put(GetSearchCommentators.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp || []);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_RATINGS_R, getRatingsWorker);
  yield takeEvery(ActionTypes.SEARCH_RATINGS_R, searchRatingsWorker);
  yield takeEvery(ActionTypes.GET_SEARCH_COMMENTATORS_R, getSearchCommentatorsWorker);
}

export default function* ratingsSaga() {
  yield all([fork(watchFetchRequest)]);
}
