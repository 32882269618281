import { Actions, TDefRequest } from '../Actions';
import ActionTypes, { TConfiguratorState } from './types';

const GetConfiguratorAction = new Actions('GET_CONFIGURATOR', ActionTypes);
export interface TypeGetConfiguratorR extends TDefRequest {
  siteId: number;
}

export const GetConfigurator = {
  request: (payload: TypeGetConfiguratorR) => GetConfiguratorAction.request(payload),
  success: (payload: TConfiguratorState['data']) => GetConfiguratorAction.success(payload || {}),
  error: (message: string) => GetConfiguratorAction.error(message),
};

const UpdateConfiguratorAction = new Actions('UPDATE_CONFIGURATOR', ActionTypes);
export interface TypeUpdateConfiguratorR extends TDefRequest {
  siteId: number;
  data: {
    pb_name: string;
    pb_url: string;
    site_id: number;
    all_updates_name: string; 
    all_updates_text: string;
  };
}

export const UpdateConfigurator = {
  request: (payload: TypeUpdateConfiguratorR) => UpdateConfiguratorAction.request(payload),
  success: (payload: TConfiguratorState['data']) => UpdateConfiguratorAction.success(payload || {}),
  error: (message: string) => UpdateConfiguratorAction.error(message),
};
