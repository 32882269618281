import { Reducer } from 'redux';
import ActionTypes, { TServicesState } from './types';

export const initialState: TServicesState = {
  blocks: null,
  services: null,
  current: null,
  loading: false,
  errors: undefined,
};

const reducer: Reducer<TServicesState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_POSITION_S:
    case ActionTypes.GET_SERVICES_S:
      return { ...state, services: action.payload };

    case ActionTypes.DELETE_BLOCK_S:
    case ActionTypes.UPDATE_BLOCKS_S:
    case ActionTypes.GET_BLOCKS_S:
      return { ...state, blocks: action.payload };

    case ActionTypes.GET_SERVICE_S:
      return { ...state, current: action.payload };

    case ActionTypes.CREATE_SERVICE_S:
      return { ...state, services: state.services ? [action.payload, ...state.services] : [action.payload] };

    case ActionTypes.UPDATE_SERVICE_S:
      return {
        ...state,
        services: state.services?.map((item) => (item.id === action.payload.id ? action.payload : item)),
      };

    case ActionTypes.DELETE_SERVICE_S:
      return {
        ...state,
        services: state.services?.filter((item) => item.id !== action.payload.id),
      };

    default:
      return state;
  }
};

export { reducer as ServicesReducer };
