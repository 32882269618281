import { Reducer } from 'redux';
import ActionTypes, { TCartState } from './types';

export const initialState: TCartState = {
  data: null,
  publications: null,
  loading: false,
  errors: undefined,
};

const reducer: Reducer<TCartState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.RESET_CART: {
      return {
        ...state,
        publications: state.data ? state.data.publications : null,
      };
    }
    case ActionTypes.SET_CART: {
      return {
        ...state,
        data: action.payload,
        publications: action.payload.publications,
        loading: false,
        errors: '',
      };
    }
    case ActionTypes.GET_CART_E:
    case ActionTypes.SEARCH_CART_E:
      return { ...state, loading: false, errors: action.payload };
    case ActionTypes.GET_CART_R:
    case ActionTypes.SEARCH_CART_R:
      return { ...state, loading: true, errors: '' };
    case ActionTypes.GET_CART_S: {
      return {
        ...state,
        publications: action.payload.publications,
        data:action.payload,
        loading: false,
        errors: '',
      };
    }
    case ActionTypes.SEARCH_CART_S: {
      return {
        ...state,
        publications: action.payload,
        loading: false,
        errors: '',
      };
    }

    default: {
      return state;
    }
  }
};

export { reducer as CartReducer };
