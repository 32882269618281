export interface TAuthorsState {
  data: { authors: IAuthor[]; total: number } | null;
  authors: IAuthor[] | null;
  readonly loading: boolean;
  readonly errors?: string | undefined;
}

export interface IAuthor {
  id: number;
  fio: string;
  position: string;
  photo: string;
  photoForVideo: string;
  city: string;
  isConsult: number;
}

enum ActionTypes {
  SET_AUTHORS = '@@authors/SET_AUTHORS',
  RESET_AUTHORS = '@@authors/RESET_AUTHORS',

  GET_SEARCH_AUTHORS_R = '@@authors/GET_SEARCH_AUTHORS_R',
  GET_SEARCH_AUTHORS_S = '@@authors/GET_SEARCH_AUTHORS_S',
  GET_SEARCH_AUTHORS_E = '@@authors/GET_SEARCH_AUTHORS_E',

  GET_AUTHORS_R = '@@authors/GET_AUTHORS_R',
  GET_AUTHORS_S = '@@authors/GET_AUTHORS_S',
  GET_AUTHORS_E = '@@authors/GET_AUTHORS_E',

  CREATE_AUTHOR_R = '@@authors/CREATE_AUTHOR_R',
  CREATE_AUTHOR_S = '@@authors/CREATE_AUTHOR_S',
  CREATE_AUTHOR_E = '@@authors/CREATE_AUTHOR_E',

  UPDATE_AUTHOR_R = '@@authors/UPDATE_AUTHOR_R',
  UPDATE_AUTHOR_S = '@@authors/UPDATE_AUTHOR_S',
  UPDATE_AUTHOR_E = '@@authors/UPDATE_AUTHOR_E',

  DELETE_AUTHOR_R = '@@authors/DELETE_AUTHOR_R',
  DELETE_AUTHOR_S = '@@authors/DELETE_AUTHOR_S',
  DELETE_AUTHOR_E = '@@authors/DELETE_AUTHOR_E',
}

export default ActionTypes;
