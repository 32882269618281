import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { buildQuery } from '../../utils/buildQuery';
import { callApi } from '../../utils/callApi';
import { LIMIT_BLOCK } from '../../utils/consts';
import {
  CreateBlock,
  DeleteBlock,
  GetMainBlocks,
  TypeCreateBlockR,
  TypeDeleteBlockR,
  TypeGetMainBlocksR,
  TypeUpdateBlockR,
  TypeDeleteSubBlockR,
  UpdateBlock,
  DeleteSubBlock,
  GetFreeIds,
  TypeGetFreeIdsR,
  ResetItemId,
  TypeResetItemIdR,
  SearchFreeIds,
  TypeSearchFreeIdsR,
} from './actions';
import ActionTypes, { IMain, ITitleIdsPairs } from './types';

function* getBlockWorker(action: ReturnType<typeof GetMainBlocks.request>): Generator {
  const { id, limit, callBack } = action.payload as TypeGetMainBlocksR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/main?siteId=${id}&limit=${limit || LIMIT_BLOCK}`,
    })) as IMain[];
    yield put(GetMainBlocks.success(resp));
  } catch (e) {
    success = false;
    yield put(GetMainBlocks.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* getFreeIdsWorker(action: ReturnType<typeof GetFreeIds.request>): Generator {
  const { siteId, callBack } = action.payload as TypeGetFreeIdsR;

  let success = true;
  let resp = null as null | ITitleIdsPairs;
  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: `/main/ids?siteId=${siteId}`,
    })) as ITitleIdsPairs;
    yield put(GetFreeIds.success(resp));
  } catch (e) {
    success = false;
    yield put(GetFreeIds.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* searchFreeIdsWorker(action: ReturnType<typeof SearchFreeIds.request>): Generator {
  const { data, callBack } = action.payload as TypeSearchFreeIdsR;

  let success = true;
  let resp = null as null | ITitleIdsPairs;
  const query = buildQuery(data);

  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: `/main/ids?${query}`,
    })) as ITitleIdsPairs;
    yield put(SearchFreeIds.success(resp));
  } catch (e) {
    success = false;
    yield put(SearchFreeIds.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* resetItemIdWorker(action: ReturnType<typeof ResetItemId.request>): Generator {
  const { id, callBack } = action.payload as TypeResetItemIdR;

  let success = true;
  try {
    yield call(callApi, {
      method: 'get',
      path: `/main/sub-block/reset/${id}`,
    });
    yield put(ResetItemId.success());
  } catch (e) {
    success = false;
    yield put(ResetItemId.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* createBlockWorker(action: ReturnType<typeof CreateBlock.request>): Generator {
  const { data, callBack } = action.payload as TypeCreateBlockR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      data,
      path: '/main/',
    })) as IMain;
    yield put(CreateBlock.success(resp));
  } catch (e) {
    success = false;
    yield put(CreateBlock.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* updateBlockWorker(action: ReturnType<typeof UpdateBlock.request>): Generator {
  const { data, id, callBack } = action.payload as TypeUpdateBlockR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'put',
      data,
      path: `/main/${id}`,
    })) as IMain;
    yield put(UpdateBlock.success(resp));
  } catch (e) {
    success = false;
    yield put(UpdateBlock.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* deleteSubBlockWorker(action: ReturnType<typeof DeleteSubBlock.request>): Generator {
  const { id, callBack } = action.payload as TypeDeleteSubBlockR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'delete',
      path: `/main/sub-block/${id}`,
    })) as IMain;
    yield put(DeleteSubBlock.success(resp));
  } catch (e) {
    success = false;
    yield put(DeleteSubBlock.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* deleteBlockWorker(action: ReturnType<typeof DeleteBlock.request>): Generator {
  const { id, callBack } = action.payload as TypeDeleteBlockR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'delete',
      path: `/main/${id}`,
    })) as IMain;
    yield put(DeleteBlock.success(resp));
  } catch (e) {
    success = false;
    yield put(DeleteBlock.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_MAIN_BLOCKS_R, getBlockWorker);
  yield takeEvery(ActionTypes.GET_FREE_IDS_R, getFreeIdsWorker);
  yield takeEvery(ActionTypes.SEARCH_FREE_IDS_R, searchFreeIdsWorker);
  yield takeEvery(ActionTypes.RESET_ITEM_ID_R, resetItemIdWorker);
  yield takeEvery(ActionTypes.CREATE_MAIN_BLOCK_R, createBlockWorker);
  yield takeEvery(ActionTypes.UPDATE_MAIN_BLOCK_R, updateBlockWorker);
  yield takeEvery(ActionTypes.DELETE_SUB_MAIN_BLOCK_R, deleteSubBlockWorker);
  yield takeEvery(ActionTypes.DELETE_MAIN_BLOCK_R, deleteBlockWorker);
}

export default function* mainSaga() {
  yield all([fork(watchFetchRequest)]);
}
