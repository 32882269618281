import { action } from 'typesafe-actions';
import { Actions, TDefRequest } from '../Actions';
import ActionTypes, { IConfigurations, TConfigurationsState } from './types';

export const ConfigurationsActions = {
  setSiteId: (payload: TConfigurationsState['siteId']) => action(ActionTypes.SET_SITE_ID, payload),
};

const GetSitesAction = new Actions('GET_SITES', ActionTypes);

export interface TypeGetSitesR extends TDefRequest {}

export const GetSites = {
  request: (payload: TypeGetSitesR) => GetSitesAction.request(payload),
  success: (payload: TConfigurationsState['sites']) => GetSitesAction.success(payload || {}),
  error: (message: string) => GetSitesAction.error(message),
};

const GetConfigurationsAction = new Actions('GET_CONFIGURATIONS', ActionTypes);

export interface TypeGetConfigurationsR extends TDefRequest {
  id: number;
}

export const GetConfigurations = {
  request: (payload: TypeGetConfigurationsR) => GetConfigurationsAction.request(payload),
  success: (payload: IConfigurations) => GetConfigurationsAction.success(payload || {}),
  error: (message: string) => GetConfigurationsAction.error(message),
};
