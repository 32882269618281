import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { buildQuery } from '../../utils/buildQuery';
import { callApi } from '../../utils/callApi';
import {
  CreateFactoidBlocks,
  CreateFactoids, DeleteFactoidBlocks,
  DeleteFactoids, GetFactoidBlocks,
  GetFactoids,
  SearchFactoids, TypeCreateFactoidBlocksR,
  TypeCreateFactoidsR, TypeDeleteFactoidBlocksR,
  TypeDeleteFactoidsR, TypeGetFactoidBlocksR,
  TypeGetFactoidsR,
  TypeSearchFactoidsR, TypeUpdateFactoidBlocksR,
  TypeUpdateFactoidsR, UpdateFactoidBlocks,
  UpdateFactoids,
} from './actions';
import ActionTypes, {IFactoidBlock, IFactoids, TFactoidsState} from './types';

function* getFactoidsWorker(action: ReturnType<typeof GetFactoids.request>): Generator {
  const { data, callBack } = action.payload as TypeGetFactoidsR;

  let success = true;
  const query = buildQuery(data);

  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/factoids?${query}`,
    })) as TFactoidsState['data'];
    yield put(GetFactoids.success(resp));
  } catch (e) {
    success = false;
    yield put(GetFactoids.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}
function* getFactoidBlocksWorker(action: ReturnType<typeof GetFactoidBlocks.request>): Generator {
  const { data, callBack } = action.payload as TypeGetFactoidBlocksR;

  let success = true;
  const query = buildQuery(data);

  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/factoid-blocks?${query}`,
    })) as TFactoidsState['dataBlocks'];
    yield put(GetFactoidBlocks.success(resp));
  } catch (e) {
    success = false;
    yield put(GetFactoidBlocks.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* searchFactoidsWorker(action: ReturnType<typeof SearchFactoids.request>): Generator {
  const { data, callBack } = action.payload as TypeSearchFactoidsR;

  let success = true;
  const query = buildQuery(data);

  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/factoids?${query}`,
    })) as TFactoidsState['data'];
    yield put(SearchFactoids.success(resp ? resp.factoids : []));
  } catch (e) {
    success = false;
    yield put(SearchFactoids.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* createFactoidsWorker(action: ReturnType<typeof CreateFactoids.request>): Generator {
  const { data, callBack } = action.payload as TypeCreateFactoidsR;

  let success = true;
  let error = null;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      data,
      path: '/factoids/',
      isFormData: true,
    })) as IFactoids;
    yield put(CreateFactoids.success(resp));
  } catch (e: any) {
    success = false;
    error = e;
    yield put(CreateFactoids.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error);
  }
}

function* createFactoidBlocksWorker(action: ReturnType<typeof CreateFactoidBlocks.request>): Generator {
  const { data, callBack } = action.payload as TypeCreateFactoidBlocksR;

  let success = true;
  let error = null;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      data,
      path: '/factoid-blocks/',
      isFormData: true,
    })) as IFactoidBlock;
    yield put(CreateFactoidBlocks.success(resp));
  } catch (e: any) {
    success = false;
    error = e;
    yield put(CreateFactoidBlocks.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error);
  }
}

function* updateFactoidsWorker(action: ReturnType<typeof UpdateFactoids.request>): Generator {
  const { data, id, callBack } = action.payload as TypeUpdateFactoidsR;

  let success = true;
  let error = null;
  try {
    const resp = (yield call(callApi, {
      method: 'put',
      data,
      path: `/factoids/${id}`,
      isFormData: true,
    })) as IFactoids;
    yield put(UpdateFactoids.success(resp));
  } catch (e: any) {
    success = false;
    error = e;
    yield put(UpdateFactoids.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error);
  }
}

function* updateFactoidBlocksWorker(action: ReturnType<typeof UpdateFactoidBlocks.request>): Generator {
  const { data, id, callBack } = action.payload as TypeUpdateFactoidBlocksR;

  let success = true;
  let error = null;
  try {
    const resp = (yield call(callApi, {
      method: 'put',
      data,
      path: `/factoid-blocks/${id}`,
      isFormData: true,
    })) as IFactoidBlock;
    yield put(UpdateFactoidBlocks.success(resp));
  } catch (e: any) {
    success = false;
    error = e;
    yield put(UpdateFactoidBlocks.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error);
  }
}

function* deleteFactoidsWorker(action: ReturnType<typeof DeleteFactoids.request>): Generator {
  const { id, callBack } = action.payload as TypeDeleteFactoidsR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'delete',
      path: `/factoids/${id}`,
    })) as IFactoids;
    yield put(DeleteFactoids.success(resp));
  } catch (e) {
    success = false;
    yield put(DeleteFactoids.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* deleteFactoidBlocksWorker(action: ReturnType<typeof DeleteFactoidBlocks.request>): Generator {
  const { id, callBack } = action.payload as TypeDeleteFactoidBlocksR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'delete',
      path: `/factoid-blocks/${id}`,
    })) as IFactoidBlock;
    yield put(DeleteFactoidBlocks.success(resp));
  } catch (e) {
    success = false;
    yield put(DeleteFactoidBlocks.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_FACTOIDS_R, getFactoidsWorker);
  yield takeEvery(ActionTypes.GET_FACTOID_BLOCKS_R, getFactoidBlocksWorker);
  yield takeEvery(ActionTypes.SEARCH_FACTOIDS_R, searchFactoidsWorker);
  yield takeEvery(ActionTypes.CREATE_FACTOIDS_R, createFactoidsWorker);
  yield takeEvery(ActionTypes.CREATE_FACTOID_BLOCKS_R, createFactoidBlocksWorker);
  yield takeEvery(ActionTypes.UPDATE_FACTOIDS_R, updateFactoidsWorker);
  yield takeEvery(ActionTypes.UPDATE_FACTOID_BLOCKS_R, updateFactoidBlocksWorker);
  yield takeEvery(ActionTypes.DELETE_FACTOIDS_R, deleteFactoidsWorker);
  yield takeEvery(ActionTypes.DELETE_FACTOID_BLOCKS_R, deleteFactoidBlocksWorker);
}

export default function* factoidsSaga() {
  yield all([fork(watchFetchRequest)]);
}
