import { Typography, Link } from '@mui/material';
import React from 'react';

export const Copyright: React.FC = () => {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'© '}
      <Link color='inherit' href='https://expertus.com.ua/'>
        Цифрове видавництво Експертус
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};
