import { Reducer } from 'redux';
import ActionTypes, { TConfiguratorState } from './types';

export const initialState: TConfiguratorState = {
  data: null,
  loading: false,
  errors: undefined,
};

const reducer: Reducer<TConfiguratorState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CONFIGURATOR_R:
    case ActionTypes.UPDATE_CONFIGURATOR_R:
      return { ...state, loading: true, errors: '' };

    case ActionTypes.GET_CONFIGURATOR_S:
    case ActionTypes.UPDATE_CONFIGURATOR_S:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case ActionTypes.GET_CONFIGURATOR_E:
    case ActionTypes.UPDATE_CONFIGURATOR_E:
      return { ...state, loading: false, errors: action.payload };

    default:
      return state;
  }
};

export { reducer as ConfiguratorReducer };
