import { Reducer } from 'redux';
import ActionTypes, { TRatingsState } from './types';

const initialState: TRatingsState = {
  loading: false,
  error: undefined,
  ratings: [],
  data: null,
  commentators: [],
  pages: 25,
  total: 0,
};

const reducer: Reducer<TRatingsState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_RATINGS: {
      return {
        ...state,
        data: action.payload,
        commentators: action.payload.commentators,
        ratings: action.payload.ratings,
        loading: false,
        errors: '',
      };
    }

    case ActionTypes.RESET_RATINGS:
      return {
        ...state,
        ratings: state.data ? state.data.ratings : [],
      };

    case ActionTypes.GET_SEARCH_COMMENTATORS_R:
    case ActionTypes.SEARCH_RATINGS_R:
    case ActionTypes.GET_RATINGS_R: {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }

    case ActionTypes.GET_SEARCH_COMMENTATORS_S: {
      return {
        ...state,
        loading: false,
        error: undefined,
        commentators: action.payload,
      };
    }

    case ActionTypes.GET_RATINGS_S: {
      return {
        ...state,
        loading: false,
        error: undefined,
        ratings: action.payload.ratings,
        data: action.payload,
        pages: action.payload.pages,
        total: action.payload.total,
      };
    }
    case ActionTypes.SEARCH_RATINGS_S:
      return {
        ...state,
        ratings: action.payload.ratings,
        loading: false,
        errors: undefined,
        pages: action.payload.pages,
        total: action.payload.total,
      };

    case ActionTypes.GET_SEARCH_COMMENTATORS_E:
    case ActionTypes.GET_RATINGS_E:
    case ActionTypes.SEARCH_RATINGS_E: {
      return { ...state, loading: false, error: action.payload };
    }

    default:
      return state;
  }
};

export { reducer as RatingsReducer };
