import { IBlock } from '../blocks/types';
import { ITitleIdsPairs } from '../main/types';


export interface TCatalogSelectionState {
  selections: IBlock[] | null;
  titleIdsPairs: ITitleIdsPairs | null;
  readonly loading: boolean;
  readonly errors?: string | undefined;
}


export interface IIds {
  [x: string]: number[];
}

enum ActionTypes {
  GET_SELECTIONS_R = '@@selections/GET_SELECTIONS_R',
  GET_SELECTIONS_S = '@@selections/GET_SELECTIONS_S',
  GET_SELECTIONS_E = '@@selections/GET_SELECTIONS_E',

  GET_FREE_IDS_R = '@@selections/GET_FREE_IDS_R',
  GET_FREE_IDS_S = '@@selections/GET_FREE_IDS_S',
  GET_FREE_IDS_E = '@@selections/GET_FREE_IDS_E',

  SEARCH_FREE_IDS_R = '@@selections/SEARCH_FREE_IDS_R',
  SEARCH_FREE_IDS_S = '@@selections/SEARCH_FREE_IDS_S',
  SEARCH_FREE_IDS_E = '@@selections/SEARCH_FREE_IDS_E',

  RESET_ITEM_ID_R = '@@selections/RESET_ITEM_ID_R',
  RESET_ITEM_ID_S = '@@selections/RESET_ITEM_ID_S',
  RESET_ITEM_ID_E = '@@selections/RESET_ITEM_ID_E',

  CREATE_SELECTION_R = '@@selections/CREATE_SELECTION_R',
  CREATE_SELECTION_S = '@@selections/CREATE_SELECTION_S',
  CREATE_SELECTION_E = '@@selections/CREATE_SELECTION_E',

  UPDATE_SELECTION_R = '@@selections/UPDATE_SELECTION_R',
  UPDATE_SELECTION_S = '@@selections/UPDATE_SELECTION_S',
  UPDATE_SELECTION_E = '@@selections/UPDATE_SELECTION_E',

  DELETE_SUB_SELECTION_R = '@@selections/DELETE_SUB_SELECTION_R',
  DELETE_SUB_SELECTION_S = '@@selections/DELETE_SUB_SELECTION_S',
  DELETE_SUB_SELECTION_E = '@@selections/DELETE_SUB_SELECTION_E',

  DELETE_SELECTION_R = '@@selections/DELETE_SELECTION_R',
  DELETE_SELECTION_S = '@@selections/DELETE_SELECTION_S',
  DELETE_SELECTION_E = '@@selections/DELETE_SELECTION_E',

  UPDATE_SELECTION_POSITIONS_R = '@@selections/UPDATE_SELECTION_POSITIONS_R',
  UPDATE_SELECTION_POSITIONS_S = '@@selections/UPDATE_SELECTION_POSITIONS_S',
  UPDATE_SELECTION_POSITIONS_E = '@@selections/UPDATE_SELECTION_POSITIONS_E',
}

export default ActionTypes;
