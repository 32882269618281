import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { callApi } from "../../utils/callApi";
import ActionTypes, { TEditorsState } from "./types";
import { GetEditorsSelect, TypeGetEditorsR } from "./actions";

function* getEditorsSelectWorker(action: ReturnType<typeof GetEditorsSelect.request>): Generator {
    const { callBack } = action.payload as TypeGetEditorsR;

    let success = true;
    let resp: any;
    try {
        resp = (yield call(callApi, {
            method: 'get',
            path: `/editors/list-of-select`,
        })) as TEditorsState['selectList'];
        yield put(GetEditorsSelect.success(resp));
    } catch (e) {
        success = false;
        yield put(GetEditorsSelect.error(e as string));
    } finally {
        if (callBack) yield call(callBack, success, resp);
    }
}

function* watchFetchRequest() {
    yield takeEvery(ActionTypes.GET_EDITORS_SELECT_R, getEditorsSelectWorker);
}

export default function* editorsSaga() {
    yield all([fork(watchFetchRequest)]);
}