import { action } from 'typesafe-actions';
import { Actions, TDefRequest } from '../Actions';
import ActionTypes, { IFormFile, TFormFilesState } from './types';

export const setData = (payload: TFormFilesState['data']) => action(ActionTypes.SET_FORM_FILE_DATA, payload);

const CreateFormFileAction = new Actions('CREATE_FORM_FILE', ActionTypes);
export interface TypeCreateFormFileR extends TDefRequest {
  data: FormData;
  url?: string;
}
export const CreateFormFile = {
  request: (payload: TypeCreateFormFileR) => CreateFormFileAction.request(payload),
  success: (payload: IFormFile) => CreateFormFileAction.success(payload || {}),
  error: (message: string) => CreateFormFileAction.error(message),
};

const UpdateFormFileAction = new Actions('UPDATE_FORM_FILE', ActionTypes);
export interface TypeUpdateFormFileR extends TDefRequest {
  data: FormData;
  id: number;
  url?: string;
}
export const UpdateFormFile = {
  request: (payload: TypeUpdateFormFileR) => UpdateFormFileAction.request(payload),
  success: (payload: IFormFile) => UpdateFormFileAction.success(payload || {}),
  error: (message: string) => UpdateFormFileAction.error(message),
};

const UpdateFormFilePositionsAction = new Actions('UPDATE_FORM_FILE_POSITIONS', ActionTypes);

interface IPosition {
  id: number | string;
  position: number;
}

export interface TypeUpdatePositionsFormFileR extends TDefRequest {
  data: {
    positions: IPosition[];
    form_id: number;
  };
}

export const UpdateFormFilePosition = {
  request: (payload: TypeUpdatePositionsFormFileR) => UpdateFormFilePositionsAction.request(payload),
  success: (payload: IFormFile[]) => UpdateFormFilePositionsAction.success(payload),
  error: (message: string) => UpdateFormFilePositionsAction.error(message),
};

const DeleteFormFileAction = new Actions('DELETE_FORM_FILE', ActionTypes);
export interface TypeDeleteFormFileR extends TDefRequest {
  id: number;
  url?: string;
  deleteAll?: boolean;
  form_id: number;
  type?: number;
}
export const DeleteFormFile = {
  request: (payload: TypeDeleteFormFileR) => DeleteFormFileAction.request(payload),
  success: (payload: IFormFile[]) => DeleteFormFileAction.success(payload || {}),
  error: (message: string) => DeleteFormFileAction.error(message),
};
