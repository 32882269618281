import React from 'react';
import { ServiceChoice } from '../../components/Services';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { DeleteBlock, UpdateBlocks } from '../../store/services/actions';
import { BLOCK_TYPES } from '../../utils/consts';

export const NavigatorsService: React.FC = () => {
  const { Services, Configurations } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  return (
    <ServiceChoice
      data={Services.blocks?.navigators}
      handleMove={(values, callBack) => {
        dispatch(
          UpdateBlocks.request({
            data: {
              siteId: Configurations.siteId,
              typeId: BLOCK_TYPES.NAVIGATORS,
              data: values,
            },
            callBack,
          })
        );
      }}
      handleRemove={(id, callBack) => {
        dispatch(
          DeleteBlock.request({
            serviceId: id,
            typeId: BLOCK_TYPES.NAVIGATORS,
            callBack,
          })
        );
      }}
    />
  );
};
