import { Reducer } from 'redux';
import ActionTypes, { TConfigurationsState } from './types';

export const initialState: TConfigurationsState = {
  configurations: null,
  sites: null,
  siteId: 0,
  loading: false,
};

const reducer: Reducer<TConfigurationsState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_SITE_ID:
      return { ...state, siteId: action.payload };
    case ActionTypes.GET_SITES_R:
    case ActionTypes.GET_CONFIGURATIONS_R:
      return { ...state, loading: true, errors: '' };
    case ActionTypes.GET_SITES_S:
      return { ...state, loading: false, sites: action.payload, siteId: !state.siteId && action.payload[0] ? action.payload[0].id : state.siteId };

    case ActionTypes.GET_CONFIGURATIONS_S:
      return { ...state, loading: false, configurations: action.payload };
    case ActionTypes.GET_SITES_E:
    case ActionTypes.GET_CONFIGURATIONS_E:
      return { ...state, loading: false, errors: action.payload };

    default:
      return state;
  }
};

export { reducer as ConfigurationsReducer };
