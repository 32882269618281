import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppStore } from '../../store/applicationState';
import { ROUTES } from '../../utils/routes';
import { Box, Button } from '@mui/material';
import { Universal } from '../../pages/widgets/Universal';
import { IAdditionalWidgets } from '../../store/widgets/types';

type PropsType = {
  element: JSX.Element;
};

export interface IEditData {
  type: 'new' | 'edit';
  data: IAdditionalWidgets | null;
}

export const WidgetsWorkSpace: React.FC<PropsType> = ({ element }: any) => {
  const [tab, setTab] = React.useState(1);
  const [editData, setEditData] = React.useState<IEditData | null | undefined>();
  const { Configurations } = useSelector((store: AppStore) => store);
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (location.pathname.includes('free-acces')) {
      setTab(2);
    } else if (location.pathname.includes('universal')) {
      setTab(3);
    } else {
      setTab(1);
    }
  }, [location.pathname, Configurations.siteId]);

  const handleChangeRoute =
    (path = '') =>
    () => {
      navigate(ROUTES.routeChange(path));
    };

  const renderRoutePage = (editData: IEditData | undefined | null) => {
    if (!location.pathname.includes('universal')) {
      return element;
    }
    return <Universal setEditData={setEditData} editData={editData} />;
  };

  return (
    <>
      <Box display={'flex'} mb={2} gap={1} width={500} flexWrap={'wrap'}>
        <Button
          variant={tab === 1 ? 'contained' : 'outlined'}
          color={tab === 1 ? 'primary' : 'inherit'}
          onClick={handleChangeRoute()}
        >
          Віджети
        </Button>
        <Button
          variant={tab === 2 ? 'contained' : 'outlined'}
          color={tab === 2 ? 'primary' : 'inherit'}
          onClick={handleChangeRoute('free-acces')}
        >
          Швидкий доступ
        </Button>

        <Button
          variant={tab === 3 ? 'contained' : 'outlined'}
          color={tab === 3 ? 'primary' : 'inherit'}
          onClick={handleChangeRoute('universal')}
        >
          Універсальний
        </Button>
        {location.pathname.includes(`universal`) ? (
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={() =>
              setEditData({
                type: 'new',
                data: null,
              })
            }
          >
            Додати
          </Button>
        ) : null}
      </Box>
      {renderRoutePage(editData)}
    </>
  );
};
