import { Actions, TDefRequest } from '../Actions';
import { IBlock } from '../blocks/types';
import { ISubBlock, ITitleIdsPairs } from '../main/types';
import ActionTypes from './types';

const GetSelectionsAction = new Actions('GET_SELECTIONS', ActionTypes);

export interface TypeGetSelectionsR extends TDefRequest {
  id: number;
}

export const GetSelections = {
  request: (payload: TypeGetSelectionsR) => GetSelectionsAction.request(payload),
  success: (payload: IBlock[]) => GetSelectionsAction.success(payload || {}),
  error: (message: string) => GetSelectionsAction.error(message),
};

const CreateSelectionAction = new Actions('CREATE_SELECTION', ActionTypes);

export interface TypeCreateSelectionR extends TDefRequest {
  data: {
    siteId: number;
    name: string;
    subBlocks: ISubBlock[];
  };
}

const GetFreeIdsAction = new Actions('GET_FREE_IDS', ActionTypes);

export interface TypeGetFreeIdsR extends TDefRequest {
  siteId: number;
}
export const GetFreeIds = {
  request: (payload: TypeGetFreeIdsR) => GetFreeIdsAction.request(payload),
  success: (payload: ITitleIdsPairs) => GetFreeIdsAction.success(payload || {}),
  error: (message: string) => GetFreeIdsAction.error(message),
};

const SearchFreeIdsAction = new Actions('SEARCH_FREE_IDS', ActionTypes);

export interface TypeSearchFreeIdsR extends TDefRequest {
  data: { siteId: number; categoryId: number; itemId: number };
}
export const SearchFreeIds = {
  request: (payload: TypeSearchFreeIdsR) => SearchFreeIdsAction.request(payload),
  success: (payload: ITitleIdsPairs) => SearchFreeIdsAction.success(payload || {}),
  error: (message: string) => GetFreeIdsAction.error(message),
};

const ResetItemIdAction = new Actions('RESET_ITEM_ID', ActionTypes);

export interface TypeResetItemIdR extends TDefRequest {
  id: number;
}
export const ResetItemId = {
  request: (payload: TypeResetItemIdR) => ResetItemIdAction.request(payload),
  success: () => ResetItemIdAction.success({}),
  error: (message: string) => ResetItemIdAction.error(message),
};

export const CreateSelection = {
  request: (payload: TypeCreateSelectionR) => CreateSelectionAction.request(payload),
  success: (payload: IBlock) => CreateSelectionAction.success(payload || {}),
  error: (message: string) => CreateSelectionAction.error(message),
};

const UpdateSelectionAction = new Actions('UPDATE_SELECTION', ActionTypes);

export interface TypeUpdateSelectionR extends TDefRequest {
  data: {
    name?: string;
    subBlocks?: ISubBlock[];
    isPublic?: number;
  };
  id: number;
}

export const UpdateSelection = {
  request: (payload: TypeUpdateSelectionR) => UpdateSelectionAction.request(payload),
  success: (payload: IBlock) => UpdateSelectionAction.success(payload || {}),
  error: (message: string) => UpdateSelectionAction.error(message),
};

const DeleteSubSelectionAction = new Actions('DELETE_SUB_SELECTION', ActionTypes);

export interface TypeDeleteSubSelectionR extends TDefRequest {
  id: number;
}

export const DeleteSubSelection = {
  request: (payload: TypeDeleteSubSelectionR) => DeleteSubSelectionAction.request(payload),
  success: (payload: IBlock) => DeleteSubSelectionAction.success(payload || {}),
  error: (message: string) => DeleteSubSelectionAction.error(message),
};

const DeleteSelectionAction = new Actions('DELETE_SELECTION', ActionTypes);

export interface TypeDeleteSelectionR extends TDefRequest {
  id: number;
}

export const DeleteSelection = {
  request: (payload: TypeDeleteSelectionR) => DeleteSelectionAction.request(payload),
  success: (payload: IBlock) => DeleteSelectionAction.success(payload || {}),
  error: (message: string) => DeleteSelectionAction.error(message),
};

export const UpdateSelectionsPositionsAction = new Actions('UPDATE_SELECTION_POSITIONS', ActionTypes);

interface IPosition {
  id: number | string;
  position: number;
}
export interface TypeUpdatePositionsSelectionR extends TDefRequest {
  data: {
    positions: IPosition[];
    site_id: number | string;
  };
}

export const UpdateSelectionPosition = {
  request: (payload: TypeUpdatePositionsSelectionR) => UpdateSelectionsPositionsAction.request(payload),
  success: (payload: IBlock[]) => UpdateSelectionsPositionsAction.success(payload),
  error: (message: string) => UpdateSelectionsPositionsAction.error(message),
};
