import { Reducer } from 'redux';
import ActionTypes, { TPublishersState, IPublisherDoc } from './types';

export const initialState: TPublishersState = {
  selectPublishers: null,
  ids: null,
  dataOpenPublications: null,
  data: null,
  editData: null,
  loading: false,
  errors: undefined,
};

const reducer: Reducer<TPublishersState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_PUBLISHERS_R:
    case ActionTypes.GET_OPEN_PUBLICATION_R:
    case ActionTypes.GET_OPEN_PUBLICATION_CATEGORY_IDS_R:
    case ActionTypes.CREATE_PUBLISHER_R:
    case ActionTypes.CREATE_OPEN_PUBLICATION_R:
    case ActionTypes.UPDATE_PUBLISHER_R:
      return { ...state, loading: true, errors: '' };
    case ActionTypes.DELETE_PUBLISHER_R:
      return { ...state, loading: true, errors: action.payload.message };

    case ActionTypes.GET_PUBLISHERS_E:
    case ActionTypes.CREATE_PUBLISHER_E:
    case ActionTypes.UPDATE_PUBLISHER_E:
    case ActionTypes.DELETE_OPEN_PUBLICATION_E:
    case ActionTypes.CREATE_OPEN_PUBLICATION_E:
    case ActionTypes.GET_OPEN_PUBLICATION_CATEGORY_IDS_E:
    case ActionTypes.GET_OPEN_PUBLICATION_E:
      return { ...state, loading: false, errors: action.payload };
    case ActionTypes.DELETE_PUBLISHER_E:
      return { ...state, loading: true, errors: action.payload.message };

    case ActionTypes.SET_PUBLISHERS:
    case ActionTypes.GET_PUBLISHERS_S:
      return { ...state, loading: false, data: action.payload };
    case ActionTypes.GET_OPEN_PUBLICATION_CATEGORY_IDS_S:
      return { ...state, loading: false, ids: action.payload };
    case ActionTypes.GET_OPEN_PUBLICATION_S:
      return { ...state, loading: false, dataOpenPublications: action.payload };

    case ActionTypes.CREATE_OPEN_PUBLICATION_S:
      return {
        ...state,
        loading: false,
        dataOpenPublications: state.dataOpenPublications
            ? {
              ...state.dataOpenPublications,
              openPublications: [...state.dataOpenPublications.publishers, action.payload]
            }
            : { publishers: [action.payload] },
      };

    case ActionTypes.CREATE_PUBLISHER_S:
      return {
        ...state,
        loading: false,
        data: state.data
          ? {
              ...state.data,
              publishers: [...state.data.publishers, action.payload]
                .sort((a: IPublisherDoc, b: IPublisherDoc) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
                .slice(0, 25),
            }
          : { currentPage: 1, pages: 1, publishers: [action.payload] },
      };

    case ActionTypes.UPDATE_PUBLISHER_S:
      return {
        ...state,
        loading: false,
        data: state.data
          ? { ...state.data, publishers: state.data.publishers.map((it) => (it.id === action.payload.id ? action.payload : it)) }
          : state.data,
      };

    case ActionTypes.DELETE_PUBLISHER_S:
      return {
        ...state,
        loading: false,
        data: state.data
          ? { ...state.data, publishers: state.data.publishers.map((it) => (it.id === action.payload.id ? action.payload : it)) }
          : state.data,
      };

    default:
      return state;
  }
};

export { reducer as PublishersReducer };
