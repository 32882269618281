import { Reducer } from 'redux';
import ActionTypes, { TBlocksState } from './types';

export const initialState: TBlocksState = {
  blocks: null,
  titleIdsPairs: null,
  loading: false,
  errors: undefined,
};

const reducer: Reducer<TBlocksState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_FREE_IDS_R:
    case ActionTypes.GET_BLOCKS_R:
    case ActionTypes.CREATE_BLOCK_R:
    case ActionTypes.UPDATE_BLOCK_R:
    case ActionTypes.UPDATE_BLOCK_POSITIONS_R:
    case ActionTypes.DELETE_BLOCK_R:
      return { ...state, loading: true, errors: '' };

    case ActionTypes.GET_BLOCKS_S:
    case ActionTypes.UPDATE_BLOCK_POSITIONS_S:
      return { ...state, loading: false, blocks: action.payload };

    case ActionTypes.CREATE_BLOCK_S:
      return { ...state, loading: false, blocks: state.blocks ? [...state.blocks, action.payload] : action.payload };

    case ActionTypes.UPDATE_BLOCK_S:
      return { ...state, loading: false, blocks: state.blocks?.map((it) => (it.id === action.payload.id ? action.payload : it)) };

    case ActionTypes.DELETE_SUB_BLOCK_S:
      return {
        ...state,
        loading: false,
        blocks: state.blocks?.map((it) =>
          it.id === action.payload.blockId ? { ...it, subBlocks: it.subBlocks.filter((sub) => sub.id !== action.payload.id) } : it
        ),
      };

    case ActionTypes.DELETE_BLOCK_S:
      return { ...state, loading: false, blocks: state.blocks?.filter((it) => it.id !== action.payload.id) };

    case ActionTypes.GET_FREE_IDS_S:
      return { ...state, loading: false, errors: undefined, titleIdsPairs: action.payload };

    case ActionTypes.GET_FREE_IDS_E:
    case ActionTypes.GET_BLOCKS_E:
    case ActionTypes.CREATE_BLOCK_E:
    case ActionTypes.UPDATE_BLOCK_E:
    case ActionTypes.DELETE_SUB_BLOCK_E:
    case ActionTypes.UPDATE_BLOCK_POSITIONS_E:
    case ActionTypes.DELETE_BLOCK_E:
      return { ...state, loading: false, errors: action.payload };

    default:
      return state;
  }
};

export { reducer as BlocksReducer };
