import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ROUTES } from '../../utils/routes';
import { useDispatch } from 'react-redux';
import { ConfigurationsActions } from '../../store/configurations/actions';
import { CalendarForm } from '../../components/Calendar/CalendarForm';

export const CreateCalendar: React.FC = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    const query = new URLSearchParams(search);
    const siteId = query.get('site_id');
    if (siteId) {
      dispatch(ConfigurationsActions.setSiteId(Number(siteId)));
    }
  }, [dispatch, search]);

 

  return (
    <CalendarForm
      type='new'
      handleCancel={() => navigate(ROUTES.routeChange(''))}
    />
  );
};
