import { Reducer } from 'redux';
import ActionTypes, { TFormFilesState } from './types';

export const initialState: TFormFilesState = {
  data: [],
  loading: false,
  errors: undefined,
};

const reducer: Reducer<TFormFilesState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CREATE_FORM_FILE_R:
    case ActionTypes.UPDATE_FORM_FILE_R:
    case ActionTypes.DELETE_FORM_FILE_R:
    case ActionTypes.UPDATE_FORM_FILE_POSITIONS_R:
      return { ...state, loading: true, errors: '' };

    case ActionTypes.CREATE_FORM_FILE_E:
    case ActionTypes.UPDATE_FORM_FILE_E:
    case ActionTypes.DELETE_FORM_FILE_E:
    case ActionTypes.UPDATE_FORM_FILE_POSITIONS_E:
      return { ...state, loading: false, errors: action.payload };

    case ActionTypes.SET_FORM_FILE_DATA:
      return { ...state, data: action.payload };

    case ActionTypes.CREATE_FORM_FILE_S:
      return { ...state, loading: false, data: action.payload };

    case ActionTypes.UPDATE_FORM_FILE_S:
    case ActionTypes.UPDATE_FORM_FILE_POSITIONS_S:
      return { ...state, loading: false, data: action.payload };

    case ActionTypes.DELETE_FORM_FILE_S:
      return { ...state, loading: false, data: action.payload };

    default:
      return state;
  }
};

export { reducer as FormFilesReducer };
