import { Reducer } from 'redux';
import ActionTypes, { TFactoidsState } from './types';

export const initialState: TFactoidsState = {
  dataBlocks: null,
  data: null,
  factoids: null,
  blocks: null,
  editData: null,
  editBlockData: null,
  listBlockData: null,
  loading: false,
  errors: undefined,
};

const reducer: Reducer<TFactoidsState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_EDIT_FACTOID:
      return {
        ...state,
        editData: action.payload,
      };

    case ActionTypes.GET_LIST_FACTOID_BLOCKS:
      return {
        ...state,
        listBlockData: action.payload,
        loading: false,
        errors: ''
      };

    case ActionTypes.SET_EDIT_BLOCK_FACTOID:
      return {
        ...state,
        editBlockData: action.payload,
      };

    case ActionTypes.SET_FACTOIDS:
      return { ...state, data: action.payload, factoids: action.payload.factoids, loading: false, errors: '' };

    case ActionTypes.GET_FACTOIDS_S:
      return { ...state, factoids: action.payload.factoids, loading: false, errors: '' };

    case ActionTypes.GET_FACTOID_BLOCKS_S:
      return { ...state, blocks: action.payload.blocks, loading: false, errors: '' };

    case ActionTypes.RESET_FACTOIDS:
      return {
        ...state,
        factoids: state.data ? state.data.factoids : null,
      };

    case ActionTypes.GET_FACTOIDS_R:
    case ActionTypes.GET_FACTOID_BLOCKS_R:
    case ActionTypes.SEARCH_FACTOIDS_R:
    case ActionTypes.CREATE_FACTOIDS_R:
    case ActionTypes.UPDATE_FACTOIDS_R:
    case ActionTypes.DELETE_FACTOIDS_R:
    case ActionTypes.DELETE_FACTOID_BLOCKS_R:
      return { ...state, loading: true, errors: '' };

    case ActionTypes.SEARCH_FACTOIDS_S:
      return {
        ...state,
        factoids: action.payload,
        loading: false,
        errors: '',
      };

    case ActionTypes.CREATE_FACTOIDS_S:
      return {
        ...state,
        loading: false,
        errors: '',
        data: state.data
          ? { ...state.data, factoids: [action.payload, ...state.data.factoids], total: state.data.total + 1 }
          : { factoids: [action.payload], total: 1 },
        factoids: state.factoids ? [action.payload, ...state.factoids] : [action.payload],
        editData: { type: 'edit', data: action.payload },
      };

    case ActionTypes.CREATE_FACTOID_BLOCKS_S:
      return {
        ...state,
        loading: false,
        errors: '',
        dataBlocks: state.dataBlocks
          ? { ...state.dataBlocks, blocks: [action.payload, ...state.dataBlocks.blocks], total: state.dataBlocks.total + 1 }
          : { blocks: [action.payload], total: 1 },
        blocks: state.blocks ? [action.payload, ...state.blocks] : [action.payload],
        editDataBlock: { type: 'edit', dataBlocks: action.payload },
      };

    case ActionTypes.UPDATE_FACTOIDS_S:
      return {
        ...state,
        loading: false,
        errors: '',
        data: state.data ? { ...state.data, factoids: state.data.factoids.map((it) => (it.id === action.payload.id ? action.payload : it)) } : state.data,
        factoids: state.factoids?.map((it) => (it.id === action.payload.id ? action.payload : it)) || null,
        editData: { type: 'edit', data: action.payload },
      };

    case ActionTypes.UPDATE_FACTOID_BLOCKS_S:
      return {
        ...state,
        loading: false,
        errors: '',
        dataBlocks: state.dataBlocks ? { ...state.dataBlocks, blocks: state.dataBlocks.blocks.map((it) => (it.id === action.payload.id ? action.payload : it)) } : state.dataBlocks,
        blocks: state.blocks?.map((it) => (it.id === action.payload.id ? action.payload : it)) || null,
        editDataBlock: { type: 'edit', dataBlocks: action.payload },
      };

    case ActionTypes.DELETE_FACTOIDS_S:
      return {
        ...state,
        loading: false,
        errors: '',
        data: state.data
          ? { ...state.data, factoids: state.data.factoids.filter((it) => it.id !== action.payload.id), total: state.data.total - 1 }
          : state.data,
        factoids: state.factoids?.filter((it) => it.id !== action.payload.id),
      };

    case ActionTypes.DELETE_FACTOID_BLOCKS_S:
      return {
        ...state,
        loading: false,
        errors: '',
        dataBlocks: state.dataBlocks
          ? { ...state.dataBlocks, blocks: state.dataBlocks.blocks.filter((it) => it.id !== action.payload.id), total: state.dataBlocks.total - 1 }
          : state.dataBlocks,
        blocks: state.blocks?.filter((it) => it.id !== action.payload.id),
      };

    case ActionTypes.GET_FACTOIDS_E:
    case ActionTypes.GET_FACTOID_BLOCKS_E:
    case ActionTypes.SEARCH_FACTOIDS_E:
    case ActionTypes.CREATE_FACTOIDS_E:
    case ActionTypes.UPDATE_FACTOIDS_E:
    case ActionTypes.DELETE_FACTOIDS_E:
    case ActionTypes.DELETE_FACTOID_BLOCKS_E:
      return { ...state, loading: false, errors: action.payload };

    default:
      return state;
  }
};

export { reducer as FactoidsReducer };
