import { IDataOpt } from "../components/ui/SelectSearchReq";

export const debounce = (callback: (data: string | IDataOpt) => void, delay: number) => {
  let data = '';
  return (value: string) => {
    data = value;
    let timeout = setTimeout(() => {
      let localData = value;
      if (data === localData) callback(data);
      clearTimeout(timeout);
    }, delay);
  };
};
