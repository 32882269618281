import { Reducer } from 'redux';
import ActionTypes, { TAuthorsState } from './types';

export const initialState: TAuthorsState = {
  data: null,
  authors: null,
  loading: false,
  errors: undefined,
};

const reducer: Reducer<TAuthorsState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_AUTHORS:
      return { ...state, data: action.payload, authors: action.payload.authors };

    case ActionTypes.GET_AUTHORS_S:
      return { ...state, authors: action.payload.authors };

    case ActionTypes.RESET_AUTHORS:
      return { ...state, authors: state.data ? state.data.authors : null };

    case ActionTypes.GET_SEARCH_AUTHORS_R:
    case ActionTypes.GET_AUTHORS_R:
    case ActionTypes.CREATE_AUTHOR_R:
    case ActionTypes.UPDATE_AUTHOR_R:
    case ActionTypes.DELETE_AUTHOR_R:
      return { ...state, loading: true, errors: '' };

    case ActionTypes.GET_SEARCH_AUTHORS_S:
      return {
        ...state,
        loading: false,
        authors: action.payload,
      };

    case ActionTypes.CREATE_AUTHOR_S:
      return {
        ...state,
        loading: false,
        data: state.data
          ? { authors: [...state.data.authors, action.payload], total: state.data.total + 1 }
          : { authors: [action.payload], total: 1 },
        authors: state.data ? [...state.data.authors, action.payload] : [action.payload],
      };

    case ActionTypes.UPDATE_AUTHOR_S:
      return {
        ...state,
        loading: false,
        data: state.data ? { ...state.data, authors: state.data?.authors.map((it) => (it.id === action.payload.id ? action.payload : it)) } : null,
        authors: state.authors?.map((it) => (it.id === action.payload.id ? action.payload : it)),
      };

    case ActionTypes.DELETE_AUTHOR_S:
      return {
        ...state,
        loading: false,
        data: state.data ? { total: state.data.total - 1, authors: state.data.authors.filter((it) => it.id !== action.payload.id) } : null,
        authors: state.authors?.filter((it) => it.id !== action.payload.id),
      };

    case ActionTypes.GET_SEARCH_AUTHORS_E:
    case ActionTypes.GET_AUTHORS_E:
    case ActionTypes.CREATE_AUTHOR_E:
    case ActionTypes.UPDATE_AUTHOR_E:
    case ActionTypes.DELETE_AUTHOR_E:
      return { ...state, loading: false, errors: action.payload };

    default:
      return state;
  }
};

export { reducer as AuthorsReducer };
