import { TEditorsState } from "./types";
import {Reducer} from "redux";
import ActionTypes from "./types";

export const initialState: TEditorsState = {
    selectList: [],
    loading: false,
    errors: undefined,
};

const reducer: Reducer<TEditorsState> = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_EDITORS_SELECT_S:
            return { ...state, loading: false, selectList: action.payload };
        case ActionTypes.GET_EDITORS_SELECT_R:
            return { ...state, loading: true, errors: '' };
        case ActionTypes.GET_EDITORS_SELECT_E:
            return { ...state, loading: false, errors: action.payload };

        default:
            return state;
    }
};

export { reducer as EditorsReducer };