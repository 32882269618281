import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { buildQuery } from '../../utils/buildQuery';
import { callApi } from '../../utils/callApi';
import {
  CreateBlock,
  DeleteBlock,
  GetBlocks,
  TypeCreateBlockR,
  TypeDeleteBlockR,
  TypeGetBlocksR,
  TypeUpdateBlockR,
  TypeDeleteSubBlockR,
  UpdateBlock,
  DeleteSubBlock,
  GetFreeIds,
  TypeGetFreeIdsR,
  SearchFreeIds,
  TypeSearchFreeIdsR,
  TypeResetItemIdR,
  ResetItemId,
  UpdateBlockPosition,
  TypeUpdatePositionsBlockR,
} from './actions';
import ActionTypes, { IBlock } from './types';
import { ITitleIdsPairs } from '../main/types';

function* getBlockWorker(action: ReturnType<typeof GetBlocks.request>): Generator {
  const { id, callBack } = action.payload as TypeGetBlocksR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/blocks/${id}`,
    })) as IBlock[];
    yield put(GetBlocks.success(resp));
  } catch (e) {
    success = false;
    yield put(GetBlocks.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* getFreeIdsWorker(action: ReturnType<typeof GetFreeIds.request>): Generator {
  const { siteId, callBack } = action.payload as TypeGetFreeIdsR;

  let success = true;
  let resp = null as null | ITitleIdsPairs;
  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: `/blocks/ids?siteId=${siteId}`,
    })) as ITitleIdsPairs;
    yield put(GetFreeIds.success(resp));
  } catch (e) {
    success = false;
    yield put(GetFreeIds.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* searchFreeIdsWorker(action: ReturnType<typeof SearchFreeIds.request>): Generator {
  const { data, callBack } = action.payload as TypeSearchFreeIdsR;

  let success = true;
  let resp = null as null | ITitleIdsPairs;
  const query = buildQuery(data);

  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: `/blocks/ids?${query}`,
    })) as ITitleIdsPairs;
    yield put(SearchFreeIds.success(resp));
  } catch (e) {
    success = false;
    yield put(SearchFreeIds.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* resetItemIdWorker(action: ReturnType<typeof ResetItemId.request>): Generator {
  const { id, callBack } = action.payload as TypeResetItemIdR;

  let success = true;
  try {
    yield call(callApi, {
      method: 'get',
      path: `/blocks/sub-block/reset/${id}`,
    });
    yield put(ResetItemId.success());
  } catch (e) {
    success = false;
    yield put(ResetItemId.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* createBlockWorker(action: ReturnType<typeof CreateBlock.request>): Generator {
  const { data, callBack } = action.payload as TypeCreateBlockR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      data,
      path: '/blocks/',
    })) as IBlock;
    yield put(CreateBlock.success(resp));
  } catch (e) {
    success = false;
    yield put(CreateBlock.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* updateBlockWorker(action: ReturnType<typeof UpdateBlock.request>): Generator {
  const { data, id, callBack } = action.payload as TypeUpdateBlockR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'put',
      data,
      path: `/blocks/${id}`,
    })) as IBlock;
    yield put(UpdateBlock.success(resp));
  } catch (e) {
    success = false;
    yield put(UpdateBlock.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* deleteSubBlockWorker(action: ReturnType<typeof DeleteSubBlock.request>): Generator {
  const { id, callBack } = action.payload as TypeDeleteSubBlockR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'delete',
      path: `/blocks/sub-block/${id}`,
    })) as IBlock;
    yield put(DeleteSubBlock.success(resp));
  } catch (e) {
    success = false;
    yield put(DeleteSubBlock.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* deleteBlockWorker(action: ReturnType<typeof DeleteBlock.request>): Generator {
  const { id, callBack } = action.payload as TypeDeleteBlockR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'delete',
      path: `/blocks/${id}`,
    })) as IBlock;
    yield put(DeleteBlock.success(resp));
  } catch (e) {
    success = false;
    yield put(DeleteBlock.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* updateBlockPositionsWorker(action: ReturnType<typeof UpdateBlockPosition.request>): Generator {
  const { data, callBack } = action.payload as TypeUpdatePositionsBlockR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      data,
      path: `/blocks/positions`,
    })) as IBlock[];
    yield put(UpdateBlockPosition.success(resp));
  } catch (e) {
    success = false;
    yield put(UpdateBlockPosition.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_BLOCKS_R, getBlockWorker);
  yield takeEvery(ActionTypes.GET_FREE_IDS_R, getFreeIdsWorker);
  yield takeEvery(ActionTypes.SEARCH_FREE_IDS_R, searchFreeIdsWorker);
  yield takeEvery(ActionTypes.RESET_ITEM_ID_R, resetItemIdWorker);
  yield takeEvery(ActionTypes.CREATE_BLOCK_R, createBlockWorker);
  yield takeEvery(ActionTypes.UPDATE_BLOCK_R, updateBlockWorker);
  yield takeEvery(ActionTypes.DELETE_SUB_BLOCK_R, deleteSubBlockWorker);
  yield takeEvery(ActionTypes.DELETE_BLOCK_R, deleteBlockWorker);
  yield takeEvery(ActionTypes.UPDATE_BLOCK_POSITIONS_R, updateBlockPositionsWorker);
}

export default function* blocksSaga() {
  yield all([fork(watchFetchRequest)]);
}
